import { type JSX, type ParentProps } from 'solid-js'

import { CheckIcon } from '../../components/Icon'
import { t } from '../../i18n'
import pic2url from '../auth/auth-2.webp'

export function OrgAuthLayout(props: ParentProps): JSX.Element {
  return (
    <div class="flex flex-col content-start">
      <div class="grid grid-cols-1 gap-8 py-6 lg:grid-cols-2">
        <div class="gap flex flex-col gap-2 lg:gap-8">{props.children}</div>
        <div>
          <div
            class="sticky top-6 hidden h-[calc(100vh-13rem)] flex-col justify-center overflow-hidden rounded-2xl bg-cover bg-center p-8 drop-shadow-2xl lg:flex"
            style={{ 'background-image': `url(${pic2url})` }}
          >
            <div class="w-full text-balance rounded-lg bg-dark-80 p-8 px-12 py-12 !leading-snug text-grey-50 text-2xl">
              {t('org.auth.desc')}
              <ul>
                <li>
                  <CheckIcon class="mr-4 mt-6 inline-block size-6 text-apple-green" />
                  <span class="font-semibold text-apple-green">
                    {t('org.auth.list1_accent')}
                  </span>
                  {t('org.auth.list1_post')}
                </li>
                <li>
                  <CheckIcon class="mr-4 mt-6 inline-block size-6 text-apple-green" />
                  {t('org.auth.list2_pre')}
                  <span class="font-semibold text-apple-green">
                    {t('org.auth.list2_accent')}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
