import { isSameDay } from 'date-fns'
import {
  batch,
  createMemo,
  createSignal,
  For,
  type JSX,
  Show,
  untrack,
} from 'solid-js'
import { onMount } from 'solid-js'

import { type DateString } from '#db/schema.constants'

import { Button } from '../../components/Button'
import {
  DATE_PRESETS,
  DatePicker,
  type DatePresetName,
} from '../../components/DatePicker'
import { LocationCombobox } from '../../components/LocationComboBox'
import { t } from '../../i18n'
import { type OptionItem } from './CitySelector'
import { DistanceSlider } from './DistanceSlider'
import { HOME_OPT_FILTERS, type HomeOptFilter } from './home.constants'

export function MobileSearchMenu(props: {
  distanceSteps: number
  inputValue: OptionItem
  dateRange: [DateString, DateString] | [DateString] | null
  optFilters: HomeOptFilter[]
  isDirty: boolean
  buttonRef: HTMLButtonElement | undefined
  setOptFilters: (filters: HomeOptFilter[]) => void
  setDateRange: (
    dateRange: [DateString, DateString] | [DateString] | null,
  ) => void
  setInputValue: (inputValue: OptionItem) => void
  setDistanceSteps: (distanceSteps: number) => void
}): JSX.Element {
  let inputRef: HTMLInputElement | undefined
  let divRef: HTMLDivElement | undefined

  const selectedStoreDatePresets = createMemo<DatePresetName | null>(() => {
    const [day1, day2] = props.dateRange ?? []
    const presetName =
      day1 != null && day2 != null
        ? (DATE_PRESETS.find(
            (p) =>
              isSameDay(p.range[0].toString(), day1) &&
              isSameDay(p.range[1].toString(), day2),
          )?.name ?? null)
        : null
    return presetName
  })

  const [openDatePicker, setOpenDatePicker] = createSignal(
    untrack(
      () => selectedStoreDatePresets() == null && props.dateRange != null,
    ),
  )

  onMount(() => {
    inputRef?.focus()
  })

  return (
    <div
      ref={divRef}
      class="pointer-events-auto order-1 mx-2.5 rounded-3xl bg-grey-50 py-5 text-medium-blue sm:hidden"
      style={{
        'margin-top': 'calc(env(safe-area-inset-top, 0px) + 0.625rem)',
      }}
    >
      <div class="flex max-h-[calc(100dvh-12rem-env(safe-area-inset-top,0px))] w-full flex-col gap-4 overflow-y-auto overflow-x-visible px-5 py-0.5">
        <div class="flex flex-col gap-3">
          <p>
            {t('city_selector.title')}
            <span class="font-semibold">{props.inputValue.name}</span>
          </p>

          <LocationCombobox
            value={props.inputValue}
            onChange={(val) => {
              props.setInputValue(val)
              setTimeout(() => {
                props.buttonRef?.focus()
              }, 0)
            }}
            mount={divRef}
            ref={inputRef}
          />

          <DistanceSlider
            value={props.distanceSteps}
            onChange={(val) => {
              props.setDistanceSteps(val)
            }}
          />
        </div>
        <div class="flex flex-col gap-3">
          <p>{t('city_selector.when_label')}</p>
          <div class="flex flex-wrap gap-4">
            <For each={DATE_PRESETS}>
              {(p) => {
                const isSelected = createMemo(
                  () => p.name === selectedStoreDatePresets(),
                )
                return (
                  <Button
                    size="lg"
                    variant={isSelected() ? 'solidInvert' : 'outlineInvert'}
                    onClick={() => {
                      batch(() => {
                        setOpenDatePicker(false)
                        props.setDateRange(
                          isSelected()
                            ? null
                            : (p.range.map((d) => d.toString()) as [
                                DateString,
                                DateString,
                              ]),
                        )
                      })
                    }}
                  >
                    {t(`date_picker.${p.name}`)}
                  </Button>
                )
              }}
            </For>
            <Button
              size="lg"
              variant={openDatePicker() ? 'solidInvert' : 'outlineInvert'}
              onclick={() => {
                batch(() => {
                  if (selectedStoreDatePresets() != null) {
                    props.setDateRange(null)
                  }
                  setOpenDatePicker((prev) => !prev)
                })
              }}
            >
              {t('date_picker.custom')}
            </Button>
          </div>
          <Show when={openDatePicker()}>
            <DatePicker
              hidePreset
              onChange={(val) => {
                props.setDateRange(val)
              }}
              value={props.dateRange}
            />
          </Show>
        </div>
        <div class="flex flex-col gap-3">
          <p>{t('city_selector.options_label')}</p>
          <div class="flex flex-wrap gap-4">
            <For each={HOME_OPT_FILTERS}>
              {(opt) => (
                <Button
                  size="lg"
                  variant={
                    props.optFilters.includes(opt)
                      ? 'solidInvert'
                      : 'outlineInvert'
                  }
                  onClick={() => {
                    const _opts = untrack(() => props.optFilters)
                    const opts = _opts.includes(opt)
                      ? _opts.filter((o) => o !== opt)
                      : [..._opts, opt]
                    props.setOptFilters(opts)
                  }}
                >
                  {t(`home.filters.${opt}`)}
                </Button>
              )}
            </For>
          </div>
        </div>
      </div>
    </div>
  )
}
