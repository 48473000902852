import { Device, type DeviceInfo } from '@capacitor/device'
import { Share } from '@capacitor/share'
import { createMediaQuery } from '@solid-primitives/media'
import { createQuery, type CreateQueryResult } from '@tanstack/solid-query'
import { type Accessor, createContext, type JSX, useContext } from 'solid-js'
import {
  doesSessionExist,
  getClaimValue,
} from 'supertokens-web-js/recipe/session'
import { PermissionClaim } from 'supertokens-web-js/recipe/userroles'

import { getEntitiesToCache } from './appStores.telefunc'
import {
  isUserPermission,
  type UserPermission,
} from './pages/auth/auth.constants'
import { getUserProfile } from './pages/auth/auth.telefunc'

type EntitiesToCache = Awaited<ReturnType<typeof getEntitiesToCache>>

type UserInfo = Exclude<Awaited<ReturnType<typeof getUserProfile>>, undefined>
type AppStoreContext = {
  deviceInfoQuery: CreateQueryResult<DeviceInfo>
  sessionQuery: CreateQueryResult<[sessionExists: boolean, timestamp: number]>
  userInfo: CreateQueryResult<UserInfo | null>
  userPermissions: CreateQueryResult<UserPermission[] | null>
  cachedEntities: CreateQueryResult<EntitiesToCache>

  isSmallScreen: Accessor<boolean>
  isDesktopScreen: Accessor<boolean>
  canShare: Promise<boolean>
}

const appStoresContext = createContext<AppStoreContext>()

export function AppStoresProvider(props: {
  children: JSX.Element
}): JSX.Element {
  const sessionQuery = createQuery(() => {
    return {
      queryKey: ['doesSessionExist'],
      queryFn: async (): Promise<[boolean, number]> => {
        const sessionExists = await doesSessionExist()
        return [sessionExists, Date.now()]
      },
    }
  })

  const isSmallScreen = createMediaQuery('(max-width: 40rem)')
  const isDesktopScreen = createMediaQuery('(min-width:64rem)')
  const userInfo = createQuery(() => {
    return {
      enabled: sessionQuery.data?.[0] === true,
      queryKey: ['fetchUserInfo', sessionQuery.data?.[1]],
      queryFn: async (): Promise<UserInfo | null> => {
        const rst = await getUserProfile()
        return rst ?? null
      },
    }
  })

  const deviceInfoQuery = createQuery(() => {
    return {
      queryKey: ['getDeviceInfo'],
      queryFn: async (): Promise<DeviceInfo> => {
        return await Device.getInfo()
      },
    }
  })

  const userPermissions = createQuery(() => {
    return {
      enabled: sessionQuery.data?.[0] === true,
      queryKey: ['getClaimValue', sessionQuery.data?.[1]],
      queryFn: async (): Promise<UserPermission[]> => {
        const rst = await getClaimValue({
          claim: PermissionClaim,
        })
        return (rst ?? []).filter(isUserPermission)
      },
    }
  })

  const cachedEntities = createQuery(() => {
    return {
      queryKey: ['getEntitiesToCache'],
      queryFn: async (): Promise<EntitiesToCache> => {
        return await getEntitiesToCache()
      },
    }
  })

  return (
    <appStoresContext.Provider
      value={{
        sessionQuery,
        userPermissions,
        userInfo,
        deviceInfoQuery,
        cachedEntities,
        isSmallScreen,
        isDesktopScreen,
        canShare: Share.canShare().then(({ value }) => value),
      }}
    >
      {props.children}
    </appStoresContext.Provider>
  )
}

export function useAppStoresCtx(): AppStoreContext {
  const ctx = useContext(appStoresContext)
  if (!ctx) {
    throw new Error('useAppStoresCtx must be used within a AppStoresProvider')
  }
  return ctx
}
