import { createSignal, For, type JSX, Show, untrack } from 'solid-js'

import { useFrontStoresCtx } from '../frontStores'
import { t } from '../i18n'
import { HOME_OPT_FILTERS } from '../pages/home/home.constants'
import {
  DropdownButtonTrigger,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './DropdownMenu'
import { SlidersIcon } from './Icon'

export function DropDownOptions(): JSX.Element {
  const [openedOption, setOpenedOption] = createSignal(false)
  const { setFilters, optsArr } = useFrontStoresCtx()

  return (
    <DropdownMenu open={openedOption()} onOpenChange={setOpenedOption}>
      <DropdownMenuTrigger>
        <DropdownButtonTrigger
          class="max-w-[350px] !leading-none"
          active={optsArr().length > 0}
          open={openedOption()}
          onClickDelete={() => {
            setFilters({ opts: [] })
          }}
        >
          <SlidersIcon class="h-6 w-6 flex-shrink-0" />
          <Show
            when={optsArr().length > 0}
            fallback={<span>{t('home.options_label')}</span>}
          >
            <div class="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
              {optsArr()
                .map((o) => t(`home.filters.${o}`))
                .join(', ')}
            </div>
          </Show>
        </DropdownButtonTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        style={{
          'transform-origin': 'var(--kb-menu-content-transform-origin)',
        }}
      >
        <For each={HOME_OPT_FILTERS}>
          {(opt) => (
            <DropdownMenuCheckboxItem
              class="pr-12"
              checked={optsArr().includes(opt)}
              onClick={() => {
                const _opts = untrack(() => optsArr())
                const opts = _opts.includes(opt)
                  ? _opts.filter((o) => o !== opt)
                  : [..._opts, opt]
                setFilters({ opts })
              }}
            >
              {t(`home.filters.${opt}`)}
            </DropdownMenuCheckboxItem>
          )}
        </For>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
