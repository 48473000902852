import { Geolocation } from '@capacitor/geolocation'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from '@solidjs/router'
import {
  createMutation,
  createQuery,
  type CreateQueryResult,
  useQueryClient,
} from '@tanstack/solid-query'
import { isSameDay } from 'date-fns'
import {
  type Accessor,
  createContext,
  createEffect,
  createMemo,
  createSignal,
  type JSX,
  untrack,
  useContext,
} from 'solid-js'
import toast from 'solid-toast'
import { type Join } from 'type-fest'

import {
  type DateString,
  type Latitude,
  type Longitude,
  type PoiId,
  type ThemeName,
} from '#db/schema.constants'

import { useAppStoresCtx } from './appStores'
import { DATE_PRESETS, type DatePresetName } from './components/DatePicker'
import {
  getFavoritesThemeNames,
  updateLatestPosition,
} from './frontStores.telefunc'
import {
  getLocalityByCoordinates,
  getRegionBySlug,
  guessPositionByIp,
} from './geoloc.telefunc'
import { t } from './i18n'
import {
  type FavFromLocationState,
  QUERY_FAVORITE_KEY,
} from './pages/favorites/favorites.constants'
import {
  addToFavorites,
  getFavoritesIds,
  removeFromFavorites,
} from './pages/favorites/favorites.telefunc'
import {
  DEFAULT_DISTANCE_KM,
  HOME_OPT_FILTERS,
  type HomeOptFilter,
  MAX_DISTANCE_KM,
} from './pages/home/home.constants'
import { useTracking } from './tracking'
import { assertDateString, clamp } from './utils'

export type HomeSearchParams = {
  dates: `${string}-${string}-${string}_${string}-${string}-${string}`
  opts: Join<HomeOptFilter[], ','>
  coords: `${number},${number}`
  dist: `${number}`
}

type PositionResult = {
  coords: [Longitude, Latitude]
  name: string
} | null

type FrontStoreContext = {
  favoritesIds: CreateQueryResult<PoiId[]>
  favThemesNames: CreateQueryResult<ThemeName[]>
  gutterWidth: Accessor<number>
  setGutterWidth: (width: number) => void
  distance: Accessor<number>
  parsedDateRange: Accessor<[DateString, DateString] | [DateString] | null>
  selectedDatePresets: Accessor<DatePresetName | null>
  optsArr: Accessor<HomeOptFilter[]>
  position: Accessor<PositionResult>
  manualPosition: Accessor<PositionResult>
  setFilters: (
    filters: Partial<{
      opts: HomeOptFilter[]
      dateRange: [DateString, DateString] | [DateString] | null
      distance: number | null
      position: { coords: [Longitude, Latitude]; name: string } | null
    }>,
  ) => void
  toggleFavorite: (
    poiId: PoiId,
    captureFrom: FavFromLocationState,
    captureThemes?: ThemeName[],
  ) => Promise<void>
}

const frontStoresContext = createContext<FrontStoreContext>()

export function FrontStoresProvider(props: {
  children: JSX.Element
}): JSX.Element {
  const queryClient = useQueryClient()
  const tracker = useTracking()
  const { deviceInfoQuery, sessionQuery, userInfo } = useAppStoresCtx()

  const updateLatestPositionMutation = createMutation(() => {
    return {
      mutationKey: ['updateLatestPosition'],
      mutationFn: async (newPosition: {
        coords: [Longitude, Latitude]
        name: string
      }): Promise<void> => {
        await updateLatestPosition(newPosition)
      },
    }
  })

  const favoritesIds = createQuery(() => {
    return {
      enabled: sessionQuery.data?.[0] === true,
      queryKey: [QUERY_FAVORITE_KEY, 'getFavoritesIds', sessionQuery.data?.[1]],
      queryFn: async (): Promise<PoiId[]> => {
        return await getFavoritesIds()
      },
    }
  })

  const favThemesNames = createQuery(() => {
    return {
      enabled: sessionQuery.data?.[0] === true,
      queryKey: ['getFavoritesThemeNames', sessionQuery.data?.[1]],
      queryFn: async (): Promise<ThemeName[]> => {
        return await getFavoritesThemeNames()
      },
    }
  })

  async function toggleFavorite(
    poiId: PoiId,
    captureFrom: FavFromLocationState,
    captureThemes?: ThemeName[],
  ): Promise<void> {
    const [user, favIds] = untrack(() => [userInfo.data, favoritesIds.data])

    if (!user) {
      console.error('failed to load user before toggling favorite')
      return
    }
    if (!favIds) {
      console.error('failed to load favoriteIds before toggling')
      return
    }
    const capturePayload = {
      eventId: poiId,
      from: captureFrom,
      eventCategory: captureThemes,
    }
    if (favIds.includes(poiId)) {
      await removeFromFavorites(poiId)
      tracker.capture('Unfavourited event', capturePayload)
      toast.success(t('toasts.on_remove_favorite'), { duration: 3000 })
    } else {
      await addToFavorites(poiId)
      tracker.capture('Favourited event', capturePayload)
      toast.success(t('toasts.on_add_favorite'), { duration: 3000 })
    }
    await queryClient.invalidateQueries({
      queryKey: [QUERY_FAVORITE_KEY],
    })
  }

  const [searchParams, setHomeSearchParams] =
    useSearchParams<HomeSearchParams>()

  const distance = createMemo(() => {
    const numDist = Number(searchParams.dist)
    if (isNaN(numDist)) {
      return DEFAULT_DISTANCE_KM
    }
    return clamp(numDist, 0, MAX_DISTANCE_KM)
  })

  const optsArr = createMemo(() => {
    return (
      searchParams.opts
        ?.split(',')
        .filter((s: string): s is HomeOptFilter =>
          HOME_OPT_FILTERS.includes(s),
        ) ?? []
    ).sort((a, b) => a.localeCompare(b))
  })

  const navigate = useNavigate()
  const location = useLocation()

  const [manualLocality, setManualLocality] = createSignal<string | null>(null)
  const localityQuery = createQuery(() => {
    return {
      enabled: manualLocality() == null && searchParams.coords != null,
      queryKey: ['getLocalityByCoordinates', searchParams.coords],
      queryFn: async (): Promise<string | null> => {
        if (searchParams.coords == null) {
          return null
        }
        const [lon, lat] = searchParams.coords.split(',').map(Number) as [
          Longitude,
          Latitude,
        ]
        const res = await getLocalityByCoordinates([lon, lat])
        return res
      },
    }
  })

  function setFilters(
    filters: Partial<{
      opts: HomeOptFilter[]
      dateRange: [DateString, DateString] | [DateString] | null
      distance: number | null
      position: { coords: [Longitude, Latitude]; name: string } | null
    }>,
  ): void {
    const newSearchParamsEntries = {
      opts: 'opts' in filters ? filters.opts?.join(',') : searchParams.opts,
      dates:
        'dateRange' in filters
          ? filters.dateRange?.join('_')
          : searchParams.dates,
      coords:
        'position' in filters && filters.position != null
          ? `${filters.position.coords[0]},${filters.position.coords[1]}`
          : 'position' in filters && filters.position == null
            ? undefined
            : searchParams.coords,
      dist:
        'distance' in filters
          ? filters.distance !== DEFAULT_DISTANCE_KM
            ? `${filters.distance}`
            : null
          : searchParams.dist,
    }
    if ('position' in filters && filters.position != null) {
      setManualLocality(filters.position.name)
    }
    if ('position' in filters && filters.position == null) {
      setManualLocality(null)
    }
    if (
      params.townSlug != null &&
      'position' in filters &&
      filters.position != null
    ) {
      const path = location.pathname.replace(/ville\/[^/]+\/?/, '')
      const searchParamsEntries = Object.entries(newSearchParamsEntries).filter(
        (entry): entry is [string, string] => entry[1] != null,
      )
      navigate(path + '?' + new URLSearchParams(searchParamsEntries).toString())
      return
    }
    setHomeSearchParams(newSearchParamsEntries)
  }

  const manualPosition = createMemo(() => {
    const name = manualLocality() ?? localityQuery.data
    if (searchParams.coords == null || name == null) {
      return null
    }
    return {
      coords: searchParams.coords.split(',').map(Number) as [
        Longitude,
        Latitude,
      ],
      name,
    }
  })

  const [gutterWidth, setGutterWidth] = createSignal(0)

  const params = useParams<{ townSlug?: string }>()

  const townPositionQuery = createQuery(() => {
    return {
      enabled: params.townSlug != null,
      queryKey: ['getTownPosition', params.townSlug],
      queryFn: async (): Promise<PositionResult> => {
        if (params.townSlug == null) {
          return null
        }
        const res = await getRegionBySlug(params.townSlug)
        return res
      },
    }
  })

  const ipPositionQuery = createQuery(() => {
    return {
      queryKey: ['getIpPosition'],
      queryFn: async (): Promise<PositionResult> => {
        const res = await guessPositionByIp()
        return res
      },
    }
  })

  const geoLocPositionQuery = createQuery(() => {
    return {
      queryKey: ['getGeoLocPosition'],
      queryFn: async (): Promise<PositionResult> => {
        try {
          const perms = await Geolocation.checkPermissions()
          if (
            perms.location !== 'granted' &&
            (deviceInfoQuery.data?.platform === 'android' ||
              deviceInfoQuery.data?.platform === 'ios')
          ) {
            // only display request twice, triggers app resume callbacks everytime
            await Geolocation.requestPermissions({ permissions: ['location'] })
          }
          const res = await Geolocation.getCurrentPosition()
          const coords = [res.coords.longitude, res.coords.latitude] as [
            Longitude,
            Latitude,
          ]
          const name = await getLocalityByCoordinates(coords)
          return {
            coords,
            name,
          }
        } catch (err) {
          t('toasts.on_geolocation_error')
          console.warn('unable to fetch position from browser', err)
          return null
        }
      },
    }
  })

  const parsedDateRange = createMemo<
    [DateString, DateString] | [DateString] | null
  >(() => {
    const [start, end] = searchParams.dates?.split('_') ?? [
      undefined,
      undefined,
    ]
    try {
      if (typeof start !== 'undefined') {
        assertDateString(start)
      }
      if (typeof end !== 'undefined') {
        assertDateString(end)
      }

      if (start && end) {
        return [start, end]
      }
      if (start) {
        return [start]
      }
      return null
    } catch (e) {
      console.error(e)
      return null
    }
  })

  const position = createMemo<{
    coords: [Longitude, Latitude]
    name: string
  } | null>((_prev) => {
    if (params.townSlug != null) {
      return townPositionQuery.data ?? null
    }
    return (
      manualPosition() ??
      geoLocPositionQuery.data ??
      ipPositionQuery.data ??
      null
    )
  }, null)

  const selectedDatePresets = createMemo<DatePresetName | null>(() => {
    const [day1, day2] = parsedDateRange() ?? []
    const presetName =
      day1 != null && day2 != null
        ? (DATE_PRESETS.find(
            (p) =>
              isSameDay(p.range[0].toString(), day1) &&
              isSameDay(p.range[1].toString(), day2),
          )?.name ?? null)
        : null
    return presetName
  })

  createEffect(() => {
    if (favThemesNames.data != null && userInfo.data != null) {
      const userProfile = userInfo.data

      // tracker.identify(userProfile.supertokenId, {
      tracker.capture('$set', {
        $set: {
          age: userProfile.preferences?.age_category ?? undefined,
          preferredCity: userProfile.locality ?? undefined,
          currentCity: position()?.name,
          work: userProfile.preferences?.job_category ?? undefined,
          kids: userProfile.preferences?.children_category ?? undefined,
          outingPreferences: userProfile.preferences?.outgoing_categories,
          interests: favThemesNames.data,
        },
      })
    }
  })

  createEffect(() => {
    const _pos = position()
    if (_pos == null) {
      return
    }
    tracker.capture('$set', {
      $set: { currentCity: _pos.name },
    })
    const _userInfo = untrack(() => userInfo.data)
    if (_userInfo != null) {
      updateLatestPositionMutation.mutate({
        coords: _pos.coords,
        name: _pos.name,
      })
    }
  })

  const _tracking_effect_home_filtered = createMemo<
    [null | DatePresetName | 'custom', HomeOptFilter[]] | null
  >((prev) => {
    const dateFilters =
      selectedDatePresets() ??
      (parsedDateRange() != null ? ('custom' as const) : null)
    const optionFilters = optsArr()
    if (
      location.pathname === '/' &&
      prev != null &&
      (prev[0] !== dateFilters || prev[1].join('') !== optionFilters.join(''))
    ) {
      tracker.capture('Home filtered', {
        dateFilters,
        optionFilters,
      })
    }
    return [dateFilters, optionFilters]
  }, null)

  const _tracking_effect_location_updated = createMemo<
    [string | undefined, number | undefined]
  >(
    (prev) => {
      const newLocation = position()?.name
      const oldLocation = prev[0]
      const newRadius = distance()
      const oldRadius = prev[1]

      if (
        location.pathname === '/' &&
        oldLocation != null &&
        oldRadius != null &&
        (newLocation !== oldLocation || newRadius !== oldRadius)
      ) {
        tracker.capture('Location updated', {
          oldLocation,
          newLocation,
          oldRadius,
          newRadius,
        })
      }
      return [newLocation, newRadius]
    },
    [undefined, undefined],
  )

  return (
    <frontStoresContext.Provider
      value={{
        favoritesIds,
        favThemesNames,
        optsArr,
        parsedDateRange,
        selectedDatePresets,
        toggleFavorite,
        distance,
        position,
        manualPosition,
        setFilters,
        gutterWidth,
        setGutterWidth,
      }}
    >
      {props.children}
    </frontStoresContext.Provider>
  )
}

export function useFrontStoresCtx(): FrontStoreContext {
  const ctx = useContext(frontStoresContext)
  if (!ctx) {
    throw new Error(
      'useFrontStoresCtx must be used within a FrontStoresProvider',
    )
  }
  return ctx
}
