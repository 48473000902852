import { A } from '@solidjs/router'
import { type JSX } from 'solid-js'

import { t } from '../i18n'
import {
  FacebookIcon,
  InstagramIcon,
  LightningIcon,
  LinkedinIcon,
} from './Icon'
import logoUrl from './logo.webp'

export function Footer(): JSX.Element {
  return (
    <footer class="mt-24 flex flex-col border-t-2 border-apple-green pb-24 lg:pb-16 2xl:pb-0">
      <div class="flex items-center justify-between py-10">
        <img src={logoUrl} class="w-32" alt="Logo" />
        <A
          href="https://lokaly.fr/createurs-devenements"
          target="_self"
          class="flex gap-2 uppercase text-grey-50 text-2xs hover:underline"
        >
          <LightningIcon class="size-4" />
          <span>{t('footer.publish_your_event')}</span>
        </A>
      </div>
      <div class="flex flex-col justify-between gap-4 pb-10 sm:flex-row">
        <div class="space-y-1 text-light-65 text-sm">
          <h4 class="mb-1.5 text-grey-50 text-lg">{t('footer.about_title')}</h4>
          <A
            href="https://lokaly.fr/notre-histoire"
            class="block hover:underline"
            target="_self"
          >
            {t('footer.discover_our_story')}
          </A>
          <A
            href="https://lokaly.fr/simplifiez-vos-sorties-locales-en-quelques-clics"
            class="block hover:underline"
            target="_self"
          >
            {t('footer.why_lokaly')}
          </A>
          <A
            href="https://lokaly.fr/commencez"
            class="block hover:underline"
            target="_self"
          >
            {t('footer.start_now')}
          </A>
          <A
            href="https://lokaly.fr/nous-contacter"
            class="block hover:underline"
            target="_self"
          >
            {t('footer.contact')}
          </A>
          <A
            href="https://lokaly.fr/le-blog"
            class="block hover:underline"
            target="_self"
          >
            {t('footer.blog')}
          </A>
        </div>

        <div class="space-y-1 text-light-65 text-sm">
          <h4 class="mb-1.5 text-grey-50 text-lg">
            {t('footer.cities_title')}
          </h4>
          <A class="block hover:underline" href="/ville/tours">
            Évènements à Tours
          </A>
          <A class="block hover:underline" href="/ville/le-mans">
            Évènements à Le Mans
          </A>
          <A class="block hover:underline" href="/ville/orleans">
            Évènements à Orléans
          </A>
          <A class="block hover:underline" href="/ville/angers">
            Évènements à Angers
          </A>
          <A class="block hover:underline" href="/ville/poitiers">
            Évènements à Poitiers
          </A>
        </div>

        {/* Social links */}
        <div class="flex justify-between text-light-65 text-sm sm:flex-col sm:justify-start sm:gap-2">
          <h4 class="mb-1.5 hidden text-grey-50 text-lg sm:block">
            {t('footer.social_network_title')}
          </h4>
          <A
            href="https://www.facebook.com/lokalyFrance"
            target="_blank"
            class="group"
            aria-label="Facebook page"
          >
            <FacebookIcon class="inline-block size-6 align-middle" />
            <span class="ml-2 hidden align-middle group-hover:underline sm:inline-block">
              Facebook
            </span>
          </A>
          <A
            href="https://instagram.com/lokalyfrance"
            target="_blank"
            class="group"
            aria-label="Instagram page"
          >
            <InstagramIcon class="inline-block size-6 align-middle" />
            <span class="ml-2 hidden align-middle group-hover:underline sm:inline-block">
              Instagram
            </span>
          </A>
          <A
            href="https://www.linkedin.com/company/lokalyfr"
            target="_blank"
            class="group"
            aria-label="Linkedin page"
          >
            <LinkedinIcon class="inline-block size-6 align-middle" />
            <span class="ml-2 hidden align-middle group-hover:underline sm:inline-block">
              Linkedin
            </span>
          </A>
        </div>
      </div>

      <div class="flex flex-col justify-between gap-6 border-t border-light-10 py-10 text-grey-600 text-xs sm:flex-row">
        <p class="order-2 sm:order-1">{t('footer.copyright')}</p>
        <div class="order-1 flex flex-col gap-4 sm:order-2 sm:flex-row sm:gap-6">
          <A
            href="https://lokaly.fr/mentions-legales"
            target="_blank"
            class="hover:underline"
          >
            {t('footer.legal_notice')}
          </A>
          <A
            href="https://lokaly.fr/politique-de-confidentialite"
            target="_blank"
            class="hover:underline"
          >
            {t('footer.privacy_policy')}
          </A>
        </div>
      </div>
    </footer>
  )
}
