import { type RouteDefinition } from '@solidjs/router'
import { type JSX, lazy, type ParentProps } from 'solid-js'

import { protectPage } from './auth-helpers'
import { RootLayout } from './components/RootLayout'
import { FrontStoresProvider } from './frontStores'
import { AppRedirect } from './pages/AppRedirect'
import { AuthLayout } from './pages/auth/AuthLayout'
import Home from './pages/home/Home'
import { NotFound } from './pages/NotFound'
import { OrgAuthLayout } from './pages/org/org-auth-layout'
import OrgLandingPage from './pages/org/org-landing-page'
import OrgLayout from './pages/org/org-layout'

export const ROUTES: RouteDefinition[] = [
  import.meta.env.MODE === 'appstaging' || import.meta.env.MODE === 'appprod'
    ? null
    : {
        path: '/org',
        component: OrgLayout,
        info: {
          bgColor: 'blue',
          bgWatermark: false,
        },
        children: [
          {
            path: '/',
            component: OrgLandingPage,
          },
          {
            path: '/auth',
            component: OrgAuthLayout,
            children: [
              {
                path: '/login',
                component: lazy(async () => import('./pages/auth/LogIn')),
              },
              {
                path: '/signup',
                component: lazy(async () => import('./pages/auth/SignUp')),
              },
              {
                path: '/email-waitroom/:email',
                component: lazy(
                  async () => import('./pages/auth/EmailWaitroom'),
                ),
              },
              {
                path: '/verify-email/',
                component: lazy(async () => import('./pages/auth/VerifyEmail')),
              },
              {
                path: '/welcome',
                component: lazy(async () => import('./pages/org/org-welcome')),
              },
              {
                path: '/forgot-password',
                component: lazy(
                  async () => import('./pages/auth/ForgotPassword'),
                ),
              },
              {
                path: '/reset-password',
                component: lazy(
                  async () => import('./pages/auth/ResetPassword'),
                ),
              },
              {
                path: '/callback/:provider',
                component: lazy(async () => import('./pages/auth/Callback')),
              },
            ],
          },
          {
            path: '/events',
            component: (props: ParentProps): JSX.Element => {
              protectPage(['poi_editor'], '/org/profile')
              return <>{props.children}</>
            },
            children: [
              {
                path: '/',
                component: lazy(
                  async () => import('./pages/org/org-events-list'),
                ),
              },
              {
                path: '/new',
                component: lazy(
                  async () => import('./pages/org/org-event-edit'),
                ),
              },
              {
                path: '/:eventid',
                component: lazy(
                  async () => import('./pages/org/org-event-edit'),
                ),
              },
            ],
          },
          {
            path: '/profile/:profileId?',
            component: lazy(async () => import('./pages/org/org-profile')),
          },
        ],
      },
  {
    path: '/',
    component: RootLayout,
    children: [
      {
        path: ['/', '/ville/:townSlug', '/home'],
        component: Home,
        info: {
          feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=L'accueil`,
        },
      },
      {
        path: [
          '/poi/:poiId',
          '/evenement/:slugFr',
          '/ville/:townSlug/evenement/:slugFr',
        ],
        component: lazy(async () => import('./pages/detail/Detail')),
        info: {
          feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Un+d%C3%A9tail`,
        },
      },
      {
        path: '/profile',
        component: lazy(async () => import('./pages/profile/Profile')),
        info: {
          bgColor: 'blue',
          feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Les+param%C3%A8tres`,
        },
      },
      {
        path: '/favorites',
        component: lazy(async () => import('./pages/favorites/Favorites')),
        info: {
          bgColor: 'pink',
          feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Les+favoris`,
        },
      },
      {
        path: ['/category/:theme', '/ville/:townSlug/category/:theme'],
        component: lazy(async () => import('./pages/category/Category')),
        info: {
          feedbackFormUrl: `https://docs.google.com/forms/d/e/1FAIpQLSciyJ8_EaFp0RGOpThELl3zAvjhgQn6zyxybDNY5ZxrWKgH-A/viewform?usp=pp_url&entry.1746446849=Liste+dans+une+cat%C3%A9gorie`,
        },
      },
      {
        path: ['/promotions/:theme?', '/ville/:townSlug/promotions/:theme?'],
        component: lazy(async () => import('./pages/promotions/Promotions')),
      },
      {
        path: '/dashboard/:listing?',
        component: lazy(async () => import('./pages/dashboard/Dashboard')),
      },
      {
        path: '/batchimport',
        component: lazy(async () => import('./pages/batchimport/BatchImport')),
      },
      {
        path: ['/app-redirect/:provider'],
        component: AppRedirect,
      },
      {
        path: '*',
        info: { title: 'Not found' },
        component: NotFound,
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        component: lazy(async () => import('./pages/auth/LogIn')),
      },
      {
        path: '/signup',
        component: lazy(async () => import('./pages/auth/SignUp')),
      },
      {
        path: '/email-waitroom/:email',
        component: lazy(async () => import('./pages/auth/EmailWaitroom')),
      },
      {
        path: '/verify-email/',
        component: lazy(async () => import('./pages/auth/VerifyEmail')),
      },
      {
        path: '/welcome',
        component: lazy(async () => {
          const wel = await import('./pages/auth/Welcome')
          return {
            default: () => (
              <FrontStoresProvider>
                <wel.default />
              </FrontStoresProvider>
            ),
          }
        }),
      },
      {
        path: '/forgot-password',
        component: lazy(async () => import('./pages/auth/ForgotPassword')),
      },
      {
        path: '/reset-password',
        component: lazy(async () => import('./pages/auth/ResetPassword')),
      },
      {
        path: '/callback/:provider',
        component: lazy(async () => import('./pages/auth/Callback')),
      },
    ],
  },
].filter(Boolean)
