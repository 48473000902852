import { Geolocation } from '@capacitor/geolocation'
import { Combobox } from '@kobalte/core/combobox'
import { createScheduled, debounce } from '@solid-primitives/scheduled'
import { createMutation, createQuery } from '@tanstack/solid-query'
import {
  batch,
  createMemo,
  createSignal,
  type JSX,
  Match,
  Show,
  Switch,
} from 'solid-js'
import toast from 'solid-toast'

import { SearchMdIcon, TargetIcon } from '#/components/Icon'
import { t } from '#/i18n'
import { type Latitude, type Longitude } from '#db/schema.constants'

import { useAppStoresCtx } from '../appStores'
import {
  getLocalityByCoordinates,
  searchLocalityByName,
} from '../geoloc.telefunc'
import { type OptionItem } from '../pages/home/CitySelector'

const NO_MATCH = 'NO_MATCH'
const LOADING = 'LOADING'

export function LocationCombobox(props: {
  value: OptionItem
  onChange: (arg0: OptionItem) => void
  mount?: Node
  ref?: HTMLInputElement
  dark?: boolean
  hideSetLocationBtn?: boolean
}): JSX.Element {
  const { deviceInfoQuery } = useAppStoresCtx()
  const [searchTerm, setSearchTerm] = createSignal('')
  const scheduled = createScheduled((fn) => debounce(fn, 500))
  const debouncedSearchTerm = createMemo<string>((prev) => {
    if (scheduled()) {
      return searchTerm()
    }
    return prev
  }, '')

  const query = createQuery(() => {
    return {
      enabled: debouncedSearchTerm().length > 0,
      initialData: [],
      queryKey: ['searchLocalityByName', debouncedSearchTerm()],
      queryFn: async (): Promise<
        Array<{
          id: string
          name: string
          fullName: string
          position: [Longitude, Latitude]
        }>
      > => searchLocalityByName(debouncedSearchTerm()),
    }
  })

  const searchRslt = createMemo<OptionItem[]>(() => {
    const s = searchTerm()
    if (query.data.length) {
      return query.data
    }
    if (s.length > 0 && query.isFetching) {
      return [
        {
          id: LOADING,
          name: s,
          fullName: s,
          disabled: true,
          position: [0 as Longitude, 0 as Latitude],
        },
      ]
    }
    if (s.length > 0) {
      return [
        {
          id: NO_MATCH,
          name: '',
          fullName: '',
          disabled: true,
          position: [0 as Longitude, 0 as Latitude],
        },
      ]
    }
    return []
  })

  const useMyPosMutation = createMutation(() => ({
    mutationFn: async (): Promise<void> => {
      try {
        const perms = await Geolocation.checkPermissions()
        if (
          perms.location !== 'granted' &&
          (deviceInfoQuery.data?.platform === 'android' ||
            deviceInfoQuery.data?.platform === 'ios')
        ) {
          // only display request twice, triggers app resume callbacks everytime
          await Geolocation.requestPermissions({ permissions: ['location'] })
        }
        const res = await Geolocation.getCurrentPosition()
        const pos = [res.coords.longitude, res.coords.latitude] as [
          Longitude,
          Latitude,
        ]
        const loc = await getLocalityByCoordinates(pos)
        props.onChange({
          fullName: loc,
          id: '',
          name: loc,
          position: pos,
        })
      } catch (err) {
        if (
          err instanceof Error &&
          err.message === 'Location services are not enabled'
        ) {
          toast.error(t('toasts.on_geolocation_disabled'))
        } else {
          toast.error(t('toasts.on_geolocation_error'))
        }
        console.warn('unable to fetch position from browser', err)
      }
    },
  }))

  return (
    <div class="flex gap-3">
      <Combobox
        class="flex-1"
        options={searchRslt()}
        optionValue="id"
        defaultFilter={() => true}
        optionTextValue="fullName"
        optionDisabled="disabled"
        optionLabel="name"
        open={searchRslt().length > 0}
        placeholder={t('city_selector.input_placeholder')}
        onInputChange={setSearchTerm}
        onChange={(item) => {
          if (item) {
            batch(() => {
              props.onChange(item)
              setSearchTerm('')
            })
          }
        }}
        itemComponent={(itemProps) => (
          <Combobox.Item
            item={itemProps.item}
            class={
              'rounded py-2.5 pl-10 data-[highlighted]:bg-grey-100 ' +
              (props.dark
                ? 'data-[highlighted]:bg-grey-800'
                : 'data-[highlighted]:bg-grey-100')
            }
          >
            <Combobox.ItemLabel class="capitalize">
              <Switch fallback={itemProps.item.rawValue.fullName}>
                <Match when={itemProps.item.rawValue.id === LOADING}>
                  <span
                    class={`italic ${props.dark ? 'text-light-50' : 'text-dark-50'}`}
                  >
                    {t('city_selector.loading')}
                  </span>
                </Match>
                <Match when={itemProps.item.rawValue.id === NO_MATCH}>
                  <span
                    class={`italic ${props.dark ? 'text-light-50' : 'text-dark-50'}`}
                  >
                    {t('city_selector.empty_results')}
                  </span>
                </Match>
              </Switch>
            </Combobox.ItemLabel>
          </Combobox.Item>
        )}
      >
        <Combobox.Control>
          <Combobox.Trigger
            class={`flex w-full flex-grow rounded px-4 py-2 data-[expanded]:bg-transparent data-[expanded]:outline data-[expanded]:outline-1 data-[expanded]:outline-grey-50 ${props.dark ? 'bg-light-10' : 'bg-dark-10'}`}
          >
            <Combobox.Icon class="mr-2 size-6">
              <SearchMdIcon class="size-6" />
            </Combobox.Icon>
            <Combobox.Input
              ref={props.ref}
              value={searchTerm()}
              class={
                'inline-flex w-full appearance-none bg-transparent text-sm focus:outline-none ' +
                (props.dark
                  ? 'placeholder:text-grey-50'
                  : 'placeholder:text-medium-blue')
              }
            />
          </Combobox.Trigger>
        </Combobox.Control>
        <Combobox.Portal mount={props.mount}>
          <Combobox.Content
            class={`relative z-50 rounded px-1 py-4 outline outline-1 drop-shadow-2xl ${
              props.dark
                ? 'bg-dark-blue text-grey-50 outline-dark-blue drop-shadow-[0_25px_25px_rgba(244,246,252,0.15)]'
                : 'bg-grey-50 text-medium-blue outline-grey-50'
            }`}
          >
            <Combobox.Listbox />
          </Combobox.Content>
        </Combobox.Portal>
      </Combobox>
      <Show when={!(props.hideSetLocationBtn === true)}>
        <button
          aria-label={t('accessibility.use_location_btn')}
          class={
            'aspect-square shrink-0 rounded-md p-2 disabled:opacity-65 ' +
            (props.dark ? 'hover:bg-light-10' : 'hover:bg-dark-10')
          }
          disabled={useMyPosMutation.isPending}
          onClick={() => {
            useMyPosMutation.mutate()
          }}
        >
          <TargetIcon class="size-6" />
        </button>
      </Show>
    </div>
  )
}
