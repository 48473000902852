import { isValid, parse } from 'date-fns'
import { titleCase } from 'title-case'
import { type ObjectEntry } from 'type-fest/source/entry'

import { type DateString, type TimeString } from '#db/schema.constants'

export function exhaustiveCheck(param: never): void {
  console.error(`exahustive check, cause: ${JSON.stringify(param)}`)
}

/**
 * Same as Object.entries() but with better typing
 * */
export function entries<T extends object>(obj: T): Array<ObjectEntry<T>> {
  return Object.entries(obj) as Array<ObjectEntry<T>>
}

/**
 * Same as Object.fromEntries() but with better typing
 * */
export function fromEntries<K extends string, T>(
  entries: Iterable<readonly [K, T]>,
): Record<K, T> {
  return Object.fromEntries(entries) as unknown as Record<K, T>
}

/**
 * Map an object by its entries (tuples key/value)
 * */
export function mapByEntries<T extends object, MappedK extends string, MappedV>(
  obj: T,
  mapper: (entry: ObjectEntry<T>) => [MappedK, MappedV],
  filter?: (entry: ObjectEntry<T>) => boolean,
): Record<MappedK, MappedV> {
  return fromEntries(
    entries(obj)
      .filter(filter ?? ((): boolean => true))
      .map(mapper),
  )
}

/**
 * Returns a promise that resolves after `waitMs` milliseconds
 */
export async function wait(waitMs: number): Promise<void> {
  await new Promise<void>((resolve) => setTimeout(resolve, waitMs))
}

/**
 * Returns a string with the first letter capitalized
 */
export function capitalizeFirstLetter<S extends string>(str: S): Capitalize<S> {
  return (str.charAt(0).toLocaleUpperCase() + str.slice(1)) as Capitalize<S>
}

export function slugify(str: string): string {
  return str
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .split(/\W+/)
    .filter(Boolean)
    .join('-')
}

export function clamp(n: number, min: number, max: number): number {
  return Math.min(Math.max(n, min), max)
}

export function shuffle<T>(array: T[]): T[] {
  return array.slice().sort(() => Math.random() - 0.5)
}

export function sample<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)]
}

export function range(start: number, end: number): number[] {
  return [...Array(end - start + 1).keys()].map((i) => i + start)
}

export function assertDateString(
  str: string,
  name = '',
): asserts str is DateString {
  const ret = isValid(parse(str, 'yyyy-MM-dd', new Date()))
  if (!ret) throw new Error(`Invalid date string ${name}: ${str}`)
}

export function assertTimeString(
  str: string,
  name = '',
): asserts str is TimeString {
  const ret =
    isValid(parse(str, 'HH:mm', new Date())) ||
    isValid(parse(str, 'HH:mm:ss', new Date()))
  if (!ret) throw new Error(`Invalid time string ${name}: ${str}`)
}

export function isInArray<T extends string[] | readonly string[]>(
  value: string | undefined,
  array: T,
): value is T[number] {
  if (!value) return false
  return array.includes(value)
}

export function addStyleToBodyForModal(): void {
  if (document.body.scrollHeight > window.innerHeight) {
    const scrollbarWidth =
      (document.getElementById('root-dvw')?.scrollWidth ??
        document.body.offsetWidth) - document.body.offsetWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`
  }
  document.body.classList.add('overflow-hidden')
}

export function removeStyleFromBodyForModal(): void {
  document.body.style.paddingRight = ''
  document.body.classList.remove('overflow-hidden')
}

export function deepEqual<T extends object>(a: T | null, b: T | null): boolean {
  if (a === b) return true
  if (a == null || typeof a !== 'object' || b == null || typeof b !== 'object')
    return false

  const keysA = Object.keys(a)
  const keysB = Object.keys(b)

  if (keysA.length !== keysB.length) return false

  for (const key of keysA) {
    if (
      !keysB.includes(key) ||
      !deepEqual(
        a[key as keyof typeof a] as unknown as object,
        b[key as keyof typeof b] as unknown as object,
      )
    )
      return false
  }
  return true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunc = (...arg: any) => any

type PipeArgs<F extends AnyFunc[], Acc extends AnyFunc[] = []> = F extends [
  (...args: infer A) => infer B,
]
  ? [...Acc, (...args: A) => B]
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any
    F extends [(...args: infer A) => any, ...infer Tail]
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Tail extends [(arg: infer B) => any, ...any[]]
      ? PipeArgs<Tail, [...Acc, (...args: A) => B]>
      : Acc
    : Acc

type LastFnReturnType<F extends AnyFunc[], Else = never> = F extends [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...arg: any) => infer R,
]
  ? R
  : Else

export function pipe<FirstFn extends AnyFunc, F extends AnyFunc[]>(
  arg: Parameters<FirstFn>[0],
  firstFn: FirstFn,
  ...fns: PipeArgs<F> extends F ? F : PipeArgs<F>
): LastFnReturnType<F, ReturnType<FirstFn>> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (fns as AnyFunc[]).reduce((acc, fn) => fn(acc), firstFn(arg))
}

export function zip<A, B>(a: A[], b: B[]): Array<[A, B]>
export function zip<A, B, C>(a: A[], b: B[], c: C[]): Array<[A, B, C]>
export function zip<A, B, C, D>(
  a: A[],
  b: B[],
  c: C[],
  d: D[],
): Array<[A, B, C, D]>
export function zip(...args: unknown[][]): unknown[] {
  const minLength = Math.min(...args.map((arr) => arr.length))
  return range(0, minLength - 1).map((i) => args.map((arr) => arr[i]))
}

const SMALL_FR_WORDS = new Set([
  'à',
  'après',
  'au',
  'aux',
  'avec',
  'ce',
  'ces',
  'chez',
  'comme',
  'dans',
  'de',
  'des',
  'du',
  'd',
  'en',
  'et',
  'hors',
  'jusque',
  'la',
  'le',
  'les',
  'lors',
  'ne',
  'ni',
  'nos',
  'or',
  'ou',
  'par',
  'pas',
  'pour',
  'près',
  'quand',
  'que',
  'qui',
  'sans',
  'se',
  'selon',
  'si',
  'sous',
  'sur',
  'un',
  'une',
  'vers',
  'y',
  'l',
  'the',
])

const WORD_FR_SEPARATORS = new Set(['—', '–', '-', '―', '/', '′', "'", '’'])

const SENTENCE_FR_TERMINATORS = new Set(['.', '!', '?'])

const TITLE_FR_TERMINATORS = new Set([
  ...SENTENCE_FR_TERMINATORS,
  ':',
  '"',
  "'",
  '”',
  ';',
])

const upperCaseRgx = /([A-Z]){4}/g

export function titleCaseIfUpperCase(str?: string | null): string | null {
  if (!str) {
    return null
  }

  if (upperCaseRgx.test(str)) {
    return titleCase(str.toLocaleLowerCase('fr-FR'), {
      locale: 'fr-FR',
      smallWords: SMALL_FR_WORDS,
      wordSeparators: WORD_FR_SEPARATORS,
      sentenceTerminators: SENTENCE_FR_TERMINATORS,
      titleTerminators: TITLE_FR_TERMINATORS,
    })
  }
  return str
}
