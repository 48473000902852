import { createScheduled, debounce } from '@solid-primitives/scheduled'
import {
  children,
  createEffect,
  createMemo,
  createSignal,
  For,
  type JSX,
  onCleanup,
  Show,
} from 'solid-js'

import { useAppStoresCtx } from '../appStores'
import { useFrontStoresCtx } from '../frontStores'
import { FadeTransition } from './FadeTransition'
import { LeftChevronIcon, RightChevronIcon } from './Icon'

/** @knipignore */
export function scrollMouseX(el: HTMLDivElement, _accessor: () => never): void {
  let isDown = false
  let startX = 0
  let scrollLeft = 0

  el.addEventListener('mousedown', (e) => {
    isDown = true
    el.classList.add('cursor-grabbing')
    startX = e.pageX - el.offsetLeft
    scrollLeft = el.scrollLeft
  })
  el.addEventListener('mouseleave', () => {
    isDown = false
    el.classList.remove('cursor-grabbing')
  })
  el.addEventListener('mouseup', () => {
    isDown = false
    el.classList.remove('cursor-grabbing')
  })
  el.addEventListener('mousemove', (e) => {
    if (!isDown) {
      return
    }
    e.preventDefault()
    const x = e.pageX - el.offsetLeft
    const walk = x - startX
    el.scrollLeft = scrollLeft - walk
  })
}

export function ScrollSection(props: {
  children: JSX.Element
  ref?: (el: HTMLDivElement) => void
}): JSX.Element {
  let scrollContainer: HTMLDivElement | undefined
  const _children = children(() => props.children)

  const { isSmallScreen } = useAppStoresCtx()
  const { gutterWidth } = useFrontStoresCtx()

  let scrollAmount = 0
  const [buttonHeight, setButtonHeight] = createSignal(100)
  const [prevButonIntersecting, setPrevButtonIntersecting] = createSignal(false)
  const [nextButtonIntersecting, setNextButtonIntersecting] =
    createSignal(false)

  const scheduled = createScheduled((fn) => debounce(fn, 300))
  const debouncedPrevButtonVisible = createMemo<boolean>((val) => {
    const value = prevButonIntersecting() && !isSmallScreen()
    return scheduled() ? value : val
  }, false)
  const debouncedNextButtonVisible = createMemo<boolean>((val) => {
    const value = nextButtonIntersecting() && !isSmallScreen()
    return scheduled() ? value : val
  }, false)

  const resizeObserver = createMemo<ResizeObserver | undefined>((prev) => {
    if (prev) {
      prev.disconnect()
    }
    if (isSmallScreen()) {
      return undefined
    }
    return new ResizeObserver((entries) => {
      scrollAmount =
        entries.at(0)?.borderBoxSize.at(0)?.inlineSize ?? scrollAmount
      setButtonHeight(
        (prev) => entries.at(0)?.borderBoxSize.at(0)?.blockSize ?? prev,
      )
    })
  })

  const firstIntersectionObs = createMemo<undefined | IntersectionObserver>(
    (prev) => {
      if (prev) {
        prev.disconnect()
      }
      if (isSmallScreen()) {
        return undefined
      }
      return new IntersectionObserver(
        (entries) => {
          const entry = entries.find((e) => e.rootBounds != null)
          if (entry == null) {
            return
          }
          setPrevButtonIntersecting(!entry.isIntersecting)
        },
        {
          rootMargin: `3000px -${gutterWidth() * 0.8}px`,
          threshold: 1,
        },
      )
    },
  )
  const lastIntersectionObs = createMemo<undefined | IntersectionObserver>(
    (prev) => {
      if (prev) {
        prev.disconnect()
      }
      if (isSmallScreen()) {
        return undefined
      }
      return new IntersectionObserver(
        (entries) => {
          const entry = entries.find((e) => e.rootBounds != null)
          if (entry == null) {
            return
          }
          setNextButtonIntersecting(!entry.isIntersecting)
        },
        {
          rootMargin: `3000px -${gutterWidth() * 0.8}px`,
          threshold: 1,
        },
      )
    },
  )

  let firstCurrentObservable: Element | null = null
  let lastCurrentObservable: Element | null = null

  createEffect(() => {
    const childArray = _children
      .toArray()
      .filter((val) => val != null && typeof val === 'object')
    const firstChild = childArray.at(0)
    const lastChild = childArray.at(-1)

    if (firstChild && firstChild instanceof Element) {
      const observableElement = firstChild.querySelector('.observable-size')
      if (observableElement != null) {
        if (firstCurrentObservable != null) {
          resizeObserver()?.unobserve(firstCurrentObservable)
          firstIntersectionObs()?.unobserve(firstCurrentObservable)
        }
        firstCurrentObservable = observableElement
        resizeObserver()?.observe(firstCurrentObservable)
        firstIntersectionObs()?.observe(firstCurrentObservable)
      }
    }

    if (lastChild && lastChild instanceof Element) {
      const observableElement = lastChild.querySelector('.observable-size')
      if (observableElement != null) {
        if (lastCurrentObservable != null) {
          lastIntersectionObs()?.unobserve(lastCurrentObservable)
        }
        lastCurrentObservable = observableElement
        lastIntersectionObs()?.observe(lastCurrentObservable)
      }
    }
  })

  onCleanup(() => {
    resizeObserver()?.disconnect()
    firstIntersectionObs()?.disconnect()
    lastIntersectionObs()?.disconnect()
  })

  return (
    <>
      <div class="lkl-full-section z-20">
        <FadeTransition>
          <Show when={debouncedPrevButtonVisible()}>
            <button
              class="absolute left-0 bg-gradient-to-r from-black to-transparent pl-2 pr-6 opacity-80 transition-opacity hover:opacity-100"
              style={{
                height: `${buttonHeight() + 1}px`,
              }}
              onClick={() => {
                scrollContainer?.scrollBy({
                  left: -scrollAmount,
                  behavior: 'smooth',
                })
              }}
            >
              <LeftChevronIcon class="size-8 opacity-100" />
            </button>
          </Show>
        </FadeTransition>
        <FadeTransition>
          <Show when={debouncedNextButtonVisible()}>
            <button
              class="absolute right-0 bg-gradient-to-l from-black to-transparent pl-6 pr-2 opacity-80 transition-opacity hover:opacity-100"
              style={{
                height: `${buttonHeight() + 1}px`,
              }}
              onClick={() => {
                scrollContainer?.scrollBy({
                  left: +scrollAmount,
                  behavior: 'smooth',
                })
              }}
            >
              <RightChevronIcon class="size-8 opacity-100" />
            </button>
          </Show>
        </FadeTransition>
      </div>
      <div
        use:scrollMouseX
        ref={(el) => {
          scrollContainer = el
          props.ref?.(el)
        }}
        class="lkl-full-section !flex snap-x snap-mandatory scroll-pl-[var(--gutter-width)] gap-4 overflow-x-scroll scroll-smooth scrollbar-none"
      >
        <For each={_children.toArray()}>
          {(child) => (
            <div class="shrink-0 snap-start first:pl-[var(--gutter-width)] last:pr-[var(--gutter-width)]">
              {child}
            </div>
          )}
        </For>
      </div>
    </>
  )
}
