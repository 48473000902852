import { type PostHog, posthog } from 'posthog-js'
import { createContext, type JSX, type ParentProps, useContext } from 'solid-js'

const TrackingContext = createContext<PostHog | null>(null)

export function TrackingProvider(props: ParentProps): JSX.Element {
  let instance: PostHog | null = null
  let ignore =
    import.meta.env.MODE === 'appstaging' ||
    window.location.host.includes('127.0.0.1') ||
    window.location.host.includes('localhost') ||
    window.location.host.includes('staging')

  const callQueue: Array<{ method: keyof PostHog; args: unknown[] }> = []

  const handler: ProxyHandler<PostHog> = {
    get(_target, propKey) {
      return (...args: unknown[]) => {
        if (ignore) {
          return
        }
        if (!instance) {
          console.log('Call to PostHog queued: ', propKey, args)
          callQueue.push({ method: propKey as keyof PostHog, args })
          return
        }
        // Once realInstance is available, just call it directly
        const actualFn: unknown = instance[propKey as keyof PostHog]
        if (typeof actualFn === 'function') {
          return actualFn.apply(instance, args) as unknown
        }
        throw new Error(`No such method: ${String(propKey)}`)
      }
    },
  }

  // The object we pass as a target to the Proxy can be empty,
  // because we handle everything via the `get` trap.
  const proxyObj = new Proxy({} as PostHog, handler)
  const timeoutHandler = setTimeout(() => {
    if (instance || ignore) {
      return
    }
    console.error(
      'PostHog instance not loaded, timeout reached, flushing queue and ignoring new calls.',
    )
    ignore = true
    callQueue.length = 0
  }, 60_000)

  if (!ignore) {
    posthog.init('phc_qwSl2EnjSHH7g40qzXAE1Q28hOc7FS8Y14bNWJLchrq', {
      api_host: 'https://eu.i.posthog.com',
      persistence: 'sessionStorage',
      // Once the real instance is loaded, pass it into our proxy
      loaded(posthogInstance) {
        if (callQueue.length) {
          console.log(
            `PostHog instance loaded flushing ${
              callQueue.length
            } events from queue`,
          )
        }
        clearTimeout(timeoutHandler)
        instance = posthogInstance
        for (const call of callQueue) {
          const { method, args } = call
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          instance[method](...args)
        }
        callQueue.length = 0
      },
    })
  }

  return (
    <TrackingContext.Provider value={proxyObj}>
      {props.children}
    </TrackingContext.Provider>
  )
}

export function useTracking(): PostHog {
  const ctx = useContext(TrackingContext)
  if (!ctx) {
    throw new Error('useTracking must be used within <TrackingProvider />')
  }
  return ctx
}
