import { useCurrentMatches } from '@solidjs/router'
import { createMemo, type JSX } from 'solid-js'

import { t } from '../i18n'
import { MessageDotSquareIcon } from './Icon'

export function FeedbackFormButton(): JSX.Element {
  const matches = useCurrentMatches()

  const feedbackFormUrl = createMemo(() => {
    const match = matches().find(
      (match) => match.route.info?.feedbackFormUrl != null,
    )
    return match?.route.info?.feedbackFormUrl as string | undefined
  })

  return (
    <a
      class="pointer-events-auto flex size-16 shrink-0 items-center justify-center rounded-full border-[3px] border-grey-50 bg-medium-blue sm:w-fit sm:px-4"
      href={feedbackFormUrl()}
      target="_blank"
    >
      <MessageDotSquareIcon class="size-6" />
      <span class="ml-2 hidden sm:inline">{t('feedback_btn')}</span>
    </a>
  )
}
