import { createVisibilityObserver } from '@solid-primitives/intersection-observer'
import { Meta, Title } from '@solidjs/meta'
import { A, useLocation, useParams } from '@solidjs/router'
import { createQuery } from '@tanstack/solid-query'
import {
  createEffect,
  createMemo,
  createSignal,
  For,
  type JSX,
  Match,
  Show,
  Switch,
} from 'solid-js'

import SeparatorCard, {
  EventCard,
  SkeletonEventCard,
} from '#/components/EventCard'
import { RightChevronIcon } from '#/components/Icon'
import { ScrollSection } from '#/components/ScrollSection'
import { t } from '#/i18n'
import { type ThemeName, THEMES } from '#db/schema.constants'
import { useFrontStoresCtx } from '#frontStores.jsx'

import { useAppStoresCtx } from '../../appStores'
import { buttonVariants } from '../../components/Button'
import { EmptyState } from '../../components/EmptyState'
import { PromotionBanner } from '../../components/PromotionBanner'
import { useTracking } from '../../tracking'
import { range } from '../../utils'
import { DEFAULT_PAGE_SIZE } from './home.constants'
import {
  getPoisByAddressDistance,
  type GetPoisResponseItem,
} from './home.telefunc'
import { RecommendedBanner } from './RecommendedBanner'
import { SearchNav } from './SearchNav'

export default function Home(): JSX.Element {
  const tracker = useTracking()

  const { userInfo, sessionQuery } = useAppStoresCtx()
  const { position, distance } = useFrontStoresCtx()

  let eventCaptured = false
  const [loadedPromotions, setLoadedPromotions] = createSignal<
    undefined | number
  >()
  const [loadedRecommendations, setLoadedRecommendations] = createSignal<
    undefined | number
  >()

  createEffect(() => {
    const _loadProm = loadedPromotions()
    const _loadReco = loadedRecommendations()
    const city = position()?.name
    if (
      !eventCaptured &&
      _loadProm != null &&
      (userInfo.data != null ? _loadReco != null : true) &&
      city != null
    ) {
      tracker.capture('Home displayed', {
        recommendedEventsNumber: _loadReco,
        sponsorisedEventsNumber: _loadProm,
        radius: distance(),
        city,
      })
      eventCaptured = true
    }
  })

  return (
    <>
      <Title>
        {t('home.meta_title', {
          city: position()?.name ?? '...',
        })}
      </Title>
      <Meta
        name="description"
        content={t('home.meta_description', {
          city: position()?.name ?? '...',
        })}
      />

      <SearchNav titlePrefix={t('home.title')} />

      <PromotionBanner
        onLoad={(e) => {
          setLoadedPromotions(e.length)
        }}
        theme={null}
      />

      <Show when={userInfo.data}>
        <RecommendedBanner onLoad={(e) => setLoadedRecommendations(e.length)} />
      </Show>

      <For each={[...THEMES]}>
        {(item, index) => (
          <>
            <Show when={sessionQuery.data?.[0] !== true && index() === 2}>
              <EmptyState
                description={t('home.signup_banner_desc')}
                title={t('home.signup_banner_title')}
                hero="default"
              >
                <A
                  class={buttonVariants({ variant: 'solid' })}
                  href={`/auth/signup`}
                >
                  {t('auth.sign_up_button')}
                </A>
              </EmptyState>
            </Show>
            <CategorySection theme={item} />
          </>
        )}
      </For>
      <Show when={sessionQuery.data?.[0] !== true}>
        <EmptyState
          description={t('home.signup_banner_desc')}
          title={t('home.signup_banner_title')}
          hero="default"
        >
          <A class={buttonVariants({ variant: 'solid' })} href={`/auth/signup`}>
            {t('auth.sign_up_button')}
          </A>
        </EmptyState>
      </Show>
    </>
  )
}

const useVisibilityObserver = createVisibilityObserver({ rootMargin: '1000px' })

function CategorySection(props: { theme: ThemeName }): JSX.Element {
  const location = useLocation()

  const params = useParams<{ townSlug?: string }>()

  const { isSmallScreen } = useAppStoresCtx()
  const { position, distance, parsedDateRange, optsArr } = useFrontStoresCtx()

  const label = createMemo(() => t(`themes.${props.theme}.label`))
  const subLabel = createMemo(() => t(`themes.${props.theme}.subLabel`))

  let visibilityDivRef: HTMLDivElement | undefined
  const isVisible = useVisibilityObserver(() => visibilityDivRef)

  const memoParams = createMemo(() => {
    const pos = position()?.coords
    const distanceKm = distance()
    const filterDate = parsedDateRange()
    const filtersOpts = optsArr()
    const themeName = props.theme

    const excludePoiId = null
    const offset = 0
    const pageSize = DEFAULT_PAGE_SIZE
    const auto_extend = true
    if (!pos) {
      return null
    }
    return {
      pos,
      distanceKm,
      themeName,
      filtersOpts,
      filterDate,
      excludePoiId,
      offset,
      pageSize,
      auto_extend,
    }
  })

  let scrollSectionRef: HTMLDivElement | undefined
  createEffect(() => {
    void memoParams()
    scrollSectionRef?.scrollBy({
      left: -scrollSectionRef.clientWidth,
      behavior: 'smooth',
    })
  })

  const enabledQuery = createMemo<boolean>((prev) => {
    const params = memoParams()
    const viz = navigator.userAgent === 'lokaly-prerender' || isVisible()
    return prev || (params != null && viz)
  }, false)

  const query = createQuery(() => {
    const params = memoParams()

    return {
      enabled: enabledQuery(),
      queryKey: ['getPoisByAddressDistance', params],
      queryFn: async (): Promise<{
        length: number
        items: GetPoisResponseItem[]
        extra_items: GetPoisResponseItem[]
      }> => {
        if (params == null) {
          return {
            length: 0,
            items: [],
            extra_items: [],
          }
        }
        const items = await getPoisByAddressDistance(params)
        return {
          length: items.length,
          items: items.filter(
            (item) => item.distance / 1000 <= params.distanceKm,
          ),
          extra_items: items.filter(
            (item) => item.distance / 1000 > params.distanceKm,
          ),
        }
      },
    }
  })

  return (
    <>
      <div ref={visibilityDivRef} />
      <Show when={!(query.isSuccess && query.data.length === 0)}>
        <div class="flex items-center justify-between pt-10">
          <h2 class="text-balance text-grey-50 text-4xl">
            <Show when={isSmallScreen()} fallback={label()}>
              <A
                href={`${
                  params.townSlug != null ? `/ville/${params.townSlug}` : ''
                }/category/${props.theme}${location.search}`}
                class="hover:underline"
                itemid=""
              >
                {label()}
              </A>
            </Show>
          </h2>

          <A
            href={`${
              params.townSlug != null ? `/ville/${params.townSlug}` : ''
            }/category/${props.theme}${location.search}`}
            class="group flex items-center gap-2"
          >
            <p class="hidden font-semibold group-hover:underline sm:inline-block">
              {t('home.see_all')}
            </p>
            <RightChevronIcon class="inline-block size-6 rounded-full transition-opacity duration-150 group-hover:opacity-100 sm:opacity-0" />
          </A>
        </div>
        <p class="text-balance pb-8 text-grey-50 text-sm">{subLabel()}</p>
        <ScrollSection
          ref={(el) => {
            scrollSectionRef = el
          }}
        >
          <Switch
            fallback={
              <For each={range(0, DEFAULT_PAGE_SIZE - 1)}>
                {() => <SkeletonEventCard />}
              </For>
            }
          >
            <Match when={query.isError}>
              <p class="text-coral">
                {query.error?.message ?? 'unknown-error'}
              </p>
            </Match>
            <Match when={query.isSuccess}>
              <For each={query.data?.items}>
                {(item, indexItem) => (
                  <EventCard
                    themeName={props.theme}
                    slugFr={item.slugFr}
                    poiId={item.id}
                    title={item.label}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    poiTime={item.poiTime}
                    distanceKm={item.distance / 1000}
                    locality={item.locality}
                    mediaFileId={item.mainMediaFileId}
                    mainMediaFileCredits={item.mainMediaFileCredits}
                    mainMediaFileLicense={item.mainMediaFileLicense}
                    randomIdx={indexItem()}
                    from="homeCategory"
                  />
                )}
              </For>
              <Show when={query.data?.extra_items.length}>
                <SeparatorCard msg={t('home.more_results')} />
              </Show>
              <For each={query.data?.extra_items}>
                {(item, indexItem) => (
                  <EventCard
                    themeName={props.theme}
                    slugFr={item.slugFr}
                    poiId={item.id}
                    title={item.label}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    poiTime={item.poiTime}
                    distanceKm={item.distance / 1000}
                    locality={item.locality}
                    mediaFileId={item.mainMediaFileId}
                    mainMediaFileCredits={item.mainMediaFileCredits}
                    mainMediaFileLicense={item.mainMediaFileLicense}
                    randomIdx={
                      (query.data?.items.length ?? 0) + 1 + indexItem()
                    }
                    from="homeCategory"
                  />
                )}
              </For>
            </Match>
          </Switch>
        </ScrollSection>
      </Show>
    </>
  )
}
