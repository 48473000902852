import type { Tagged } from 'type-fest'

import {
  type AgeCategory,
  type ChildrenCategory,
  type JobCategory,
  type OutgoingCategory,
} from '../pages/profile/profile.constants'

export type TranslatableText = Partial<
  Record<'de' | 'pt' | 'en' | 'it' | 'fr' | 'es' | 'nl', string>
>

export type WeekDaysBoolDict = Record<
  'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun',
  boolean
>
/** String in YYYY-MM-DD format */

export type DateString = Tagged<string, 'DateString'>
/** String in HH:mm format */
export type TimeString = Tagged<string, 'TimeString'>

export type Longitude = Tagged<number, 'Longitude'>
export type Latitude = Tagged<number, 'Latitude'>

export type PoiId = Tagged<string, 'PoiId'>
export type MediaFileId = Tagged<string, 'MediaFileId'>
export type LargeObjectId = Tagged<number, 'LargeObjectId'>
export type TourPracticeConditionId = Tagged<string, 'TourPracticeConditionId'>
export type FeatureName = Tagged<string, 'FeatureName'>
export type PriceSpecId = Tagged<string, 'PriceSpecId'>
export type ContactId = Tagged<string, 'ContactId'>
export type AddressId = Tagged<string, 'AddressId'>
export type OrgProfileId = Tagged<string, 'OrgProfileId'>
export type RegionId = Tagged<string, 'RegionId'>
export type UserPreferences = {
  age_category?: AgeCategory | null
  children_category?: ChildrenCategory | null
  job_category?: JobCategory | null
  outgoing_categories?: OutgoingCategory[]
}
export type SupertokenId = Tagged<string, 'supertokenId'>
export type BrevoContactId = Tagged<string, 'BrevoContactId'>
export type PromotionCampaignId = Tagged<string, 'PromotionCampaignId'>
export type UserTrackingId = Tagged<string, 'UserTrackingId'>
export type EmailQueueId = Tagged<string, 'EmailQueueId'>
export type ExternalSource = Tagged<string, 'ExternalSource'>

export const THEMES = [
  'spectacles',
  'concerts',
  'degustations',
  'promenades-a-pied',
  'promenades-a-velo',
  'promenades-a-cheval',
  'tourisme',
  'famille',
  'sports',
  'evenements-artistiques',
  'theatre',
  'musees',
  'marches-et-brocantes',
  'cinema',
  'conference',
  'expositions',
  'fetes',
  'son-et-lumiere-feu-d-artifice',
] as const
export type ThemeName = (typeof THEMES)[number]

export const PRICE_MODES = [
  'SubscriptionPackage',
  'PerAnimal',
  'PerWeek',
  'PerUnit',
  'PerHour',
  'FourPeople',
  'PerPerson',
  'HalfDayPackage',
  'Weekend',
  'PerDay',
] as const
export type PriceModeName = (typeof PRICE_MODES)[number]

export const PRICE_POLICIES = [
  'BaseRateFullRate',
  'ChildRate',
  'ExtraCharge',
  'Free',
  'Group',
  'ReducedRate',
] as const
export type PricePolicyName = (typeof PRICE_POLICIES)[number]

export const FEATURE_GROUPS = [
  'CuisineCategory',
  'PaymentMethod',
  'ArchitecturalStyle',
  'CulturalTheme',
  'SportsTheme',
  'SpatialEnvironmentTheme',
  'HealthTheme',
  'ParkAndGardenTheme',
  'FoodProduct',
  'EntertainmentAndEventTheme',
  'Theme',
  'CommonAmenity',
  'CateringAmenity',
  'PeopleAudience',
  'InformativeAmenity',
  'AccommodationAmenity',
  'AccommodationPricingOffer',
  'GeneralPricingOffer',
  'CampingAndCaravanningAmenity',
  'MTBRouteTheme',
  'RentalPricingOffer',
  'CulturalHeritageAmenity',
  'RoomAmenity',
  'TypeOfBed',
  'NaturalHeritageAmenity',
] as const
export type FeatureGroupName = (typeof FEATURE_GROUPS)[number]

export const REGION_LEVELS = [
  'COUNTRY',
  'FRENCH_REGION',
  'FRENCH_DEPARTMENT',
  'CITY',
] as const
export type RegionLevelName = (typeof REGION_LEVELS)[number]

export const TOUR_DIFF_LEVELS = [
  'DifficultTour',
  'MediumDifficultyTour',
  'VeryDifficultTour',
  'EasyTour',
  'VeryEasyTour',
] as const
export type TourDiffLevelName = (typeof TOUR_DIFF_LEVELS)[number]

export const LOCOMOTION_MODES = [
  'DownhillskiingMode',
  'HorsebackMode',
  'MotorbikeMode',
  'ElectricassistedbikeMode',
  'EAMBMode',
  'TrailMode',
  'CanoeMode',
  'BabystrollerMode',
  'SnowshoeingMode',
  'FluvialMode',
  'CarMode',
  'HBMode',
  'BusMode',
  'GravelbikeMode',
  'RoadbikeMode',
  'MBMode',
  'WalkingMode',
] as const
export type LocomotionModeName = (typeof LOCOMOTION_MODES)[number]

export const OUTSIDE_FEATURES = [
  'Outdoor',
  'InTheCountryside',
  'NearARiverOrStream',
  'InTheForest',
  'InTheMountains',
  'Lakeside',
  'InARemoteLocation',
  'AtAFarm',
  'FacingTheSea',
  'ByTheSea',
  'NatureAndEnvironment',
  'FaunaFlora',
  'StreetArt',
  'Maritime',
  'Firework',
  'Astrology',
] as const

export const IMPAIRMENT_FEATURES = [
  'IntellectualImpairment',
  'HearingImpairment',
  'VisualImpairment',
  'PsychicImpairment',
  'MotorImpairment',
  'ReducedMobilityAccess',
] as const

export const KIDS_FRIENDLY_FEATURES = [
  'KidsFriendly',
  'Christmas',
  'Halloween',
  'ScienceFair',
  'SaintNicholasDay',
  'HeritageDays',
  'Easter',
  'FrenchMusicFestival',
] as const

export const FREE_FEATURES = ['Free']

export const OUTSIDE_THEMES = [
  'promenades-a-pied',
  'promenades-a-cheval',
  'promenades-a-velo',
  'fetes',
  'marches-et-brocantes',
  'son-et-lumiere-feu-d-artifice',
] as const satisfies ThemeName[]

export const KIDS_FRIENDLY_THEMES = ['famille'] as const satisfies ThemeName[]

export const CAMPAIGN_STATUSES = ['draft', 'active'] as const
export type CampaignStatusName = (typeof CAMPAIGN_STATUSES)[number]

export const TRACKING_EVENTS_THEME = [
  'add_fav_theme',
  'remove_fav_theme',
  'view_detail_theme',
] as const

export const TRACKING_EVENTS_POI = [
  'add_fav_poi',
  'remove_fav_poi',
  'view_detail_poi',
] as const

export const TRACKING_EVENTS_ALL = [
  ...TRACKING_EVENTS_THEME,
  ...TRACKING_EVENTS_POI,
] as const

export const CONTACT_KINDS = ['owner', 'booking'] as const
export type ContactKindName = (typeof CONTACT_KINDS)[number]

export const EMAIL_TEMPLATE_NAMES = [
  'weekly_recommendation',
  'favorite_reminder',
] as const
export type EmailTemplateName = (typeof EMAIL_TEMPLATE_NAMES)[number]

export const EMAIL_QUEUE_STATUSES = ['pending', 'sent', 'failed'] as const
export type EmailQueueStatus = (typeof EMAIL_QUEUE_STATUSES)[number]

export const POI_STATUSES = [
  'draft',
  'in-review',
  'accepted',
  'refused',
  'published',
  'archived',
] as const
export type PoiStatus = (typeof POI_STATUSES)[number]
