import { createQuery } from '@tanstack/solid-query'
import {
  createEffect,
  createMemo,
  For,
  type JSX,
  Match,
  Show,
  Switch,
} from 'solid-js'

import { useAppStoresCtx } from '../../appStores'
import { EventCard, SkeletonEventCard } from '../../components/EventCard'
import { ScrollSection } from '../../components/ScrollSection'
import { type ThemeName } from '../../db/schema.constants'
import { useFrontStoresCtx } from '../../frontStores'
import { t } from '../../i18n'
import { range } from '../../utils'
import { DEFAULT_PAGE_SIZE } from './home.constants'
import { type GetPoisResponseItem, getRecommendedPois } from './home.telefunc'

export function RecommendedBanner(props: {
  onLoad?: (
    event: Array<GetPoisResponseItem & { theme: ThemeName | null }>,
  ) => void
}): JSX.Element {
  const { userInfo } = useAppStoresCtx()
  const { position, distance, parsedDateRange, optsArr } = useFrontStoresCtx()

  const memoParams = createMemo(() => {
    const pos = position()?.coords
    const distanceKm = distance()
    const filterDate = parsedDateRange()
    const filtersOpts = optsArr()
    const offset = 0
    const pageSize = DEFAULT_PAGE_SIZE
    if (!pos) {
      return null
    }
    return {
      pos,
      distanceKm,
      filtersOpts,
      filterDate,
      offset,
      pageSize,
    }
  })

  const recommendationQuery = createQuery(() => {
    const params = memoParams()
    return {
      enabled: params != null,
      queryKey: ['getRecommendedPois', params],
      queryFn: async (): Promise<
        Array<GetPoisResponseItem & { theme: ThemeName }>
      > => {
        if (!params) {
          return []
        }
        const rslt = await getRecommendedPois(params)
        return rslt
      },
    }
  })

  createEffect(() => {
    if (!recommendationQuery.data) {
      return
    }
    props.onLoad?.(recommendationQuery.data)
  })

  let scrollSectionRef: HTMLDivElement | undefined
  createEffect(() => {
    void memoParams()
    scrollSectionRef?.scrollBy({
      left: -scrollSectionRef.clientWidth,
      behavior: 'smooth',
    })
  })

  return (
    <>
      <Show
        when={
          !(
            recommendationQuery.isSuccess &&
            recommendationQuery.data.length <= 0
          )
        }
      >
        <div class="flex items-baseline justify-between pt-10">
          <h2 class="text-balance text-grey-50 text-4xl">
            {t('home.recommendation_title', {
              firstName: userInfo.data?.firstName ?? '',
            })}
          </h2>
        </div>
        <p class="text-balance pb-8 text-grey-50 text-sm">
          {t('home.recommendation_subtitle')}
        </p>
      </Show>
      <ScrollSection
        ref={(el) => {
          scrollSectionRef = el
        }}
      >
        <Switch
          fallback={
            <For each={range(0, DEFAULT_PAGE_SIZE - 1)}>
              {() => <SkeletonEventCard size="lg" />}
            </For>
          }
        >
          <Match when={recommendationQuery.isError}>
            <p class="text-coral">
              {recommendationQuery.error?.message ?? 'unknown-error'}
            </p>
          </Match>
          <Match when={recommendationQuery.isSuccess}>
            <For each={recommendationQuery.data}>
              {(item, index) => (
                <EventCard
                  themeName={item.theme}
                  slugFr={item.slugFr}
                  poiId={item.id}
                  title={item.label}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  poiTime={item.poiTime}
                  distanceKm={item.distance / 1000}
                  locality={item.locality}
                  mediaFileId={item.mainMediaFileId}
                  mainMediaFileCredits={item.mainMediaFileCredits}
                  mainMediaFileLicense={item.mainMediaFileLicense}
                  randomIdx={index()}
                  size="lg"
                  from="homeRecommendation"
                />
              )}
            </For>
          </Match>
        </Switch>
      </ScrollSection>
    </>
  )
}
