import { A, useLocation, useParams } from '@solidjs/router'
import { type JSX, Show } from 'solid-js'

import {
  ChevronDownIcon,
  HeartIcon,
  MenuIcon,
  // SearchMdIcon,
  UserIcon,
} from '#/components/Icon'
import { buttonVariants } from '#components/Button.jsx'

import { useAppStoresCtx } from '../appStores'
import { IS_MOBILEAPP } from '../config'
import { t } from '../i18n'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './DropdownMenu'
import logoUrl from './logo.webp'

export function Header(): JSX.Element {
  const { sessionQuery, userPermissions, userInfo } = useAppStoresCtx()

  // const searchPlaceholder = (): string => {
  //   return isSmallScreen()
  //     ? t('header.search_placeholder_short')
  //     : t('header.search_placeholder')
  // }
  const params = useParams<{ townSlug?: string }>()
  const location = useLocation()

  if (!sessionQuery.isFetching) {
    void sessionQuery.refetch()
  }

  return (
    <header
      class={
        'flex items-center justify-between gap-6 py-2 ' +
        (IS_MOBILEAPP ? '' : 'h-24')
      }
    >
      <div class="flex w-full items-center justify-start gap-6">
        {IS_MOBILEAPP ? (
          <div />
        ) : (
          <A
            href={`${params.townSlug != null ? `/ville/${params.townSlug}` : '/'}${location.search}`}
            class="w-32 min-w-24"
          >
            <img src={logoUrl} alt="Logo" />
          </A>
        )}
        <Show
          when={userPermissions.data?.find(
            (p) => p === 'image_mod' || p === 'theme_mod',
          )}
        >
          <A href="/dashboard" class="text-nowrap text-sm hover:underline">
            {t('dashboard.title')}
          </A>
        </Show>
        <Show when={userPermissions.data?.includes('batch_import')}>
          <A href="/batchimport" class="text-nowrap text-sm hover:underline">
            {t('batchimport.title')}
          </A>
        </Show>
        {/* <div class="relative block h-11 items-center justify-start gap-6 text-grey-300">
          <SearchMdIcon class="absolute left-4 top-[calc(50%-0.8rem)] h-6 w-6" />
          <input
            type="text"
            class="placeholder-shown::text-ellipsis w-full rounded-md bg-dark-20 py-2 pl-12 pr-4 font-normal text-base"
            placeholder={searchPlaceholder()}
          />
        </div> */}
      </div>
      <button class="hidden shrink-0 py-2 sm:block lg:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <MenuIcon class="size-8" />
          </DropdownMenuTrigger>
          <DropdownMenuContent class="hidden flex-col gap-2 sm:block lg:hidden">
            <A
              href={`/favorites${location.search}`}
              class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 hover:bg-grey-100"
            >
              <HeartIcon class="size-4" />
              {t('header.favorites')}
            </A>
            <Show
              when={userInfo.data}
              fallback={
                <A
                  href="/auth/login"
                  class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 font-semibold hover:bg-grey-100"
                >
                  <UserIcon class="size-4" />
                  {t('header.sign_in')}
                </A>
              }
            >
              {(info) => (
                <A
                  href={`/profile${location.search}`}
                  class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 hover:bg-grey-100"
                >
                  <UserIcon class="size-4" />
                  {info().firstName}
                </A>
              )}
            </Show>
          </DropdownMenuContent>
        </DropdownMenu>
      </button>
      <div class="hidden items-center justify-start gap-8 py-2 lg:flex">
        <A
          href={`/favorites${location.search}`}
          class="text-nowrap text-sm hover:underline"
        >
          <HeartIcon class="mr-2 inline-block size-4 align-middle" />
          {t('header.favorites')}
        </A>
        <Show
          when={userInfo.data}
          fallback={
            <A
              class={buttonVariants({ variant: 'outline', size: 'lg' })}
              href="/auth/login"
            >
              {t('header.sign_in')}
            </A>
          }
        >
          {(info) => (
            <A
              href={`/profile${location.search}`}
              class={buttonVariants({ variant: 'solid' })}
            >
              <UserIcon class="mr-2 inline-block size-6" />
              {info().firstName}
              <ChevronDownIcon class="ml-2 inline-block size-4" />
            </A>
          )}
        </Show>
      </div>
    </header>
  )
}
