import {
  A,
  useBeforeLeave,
  useIsRouting,
  useLocation,
  useNavigate,
  useParams,
} from '@solidjs/router'
import {
  createEffect,
  createSignal,
  type JSX,
  onMount,
  type ParentProps,
  Show,
  SuspenseList,
} from 'solid-js'
import { Portal } from 'solid-js/web'
import toast from 'solid-toast'
import { onAbort } from 'telefunc/client'

import { IS_MOBILEAPP } from '../config'
import { FrontStoresProvider, useFrontStoresCtx } from '../frontStores'
import { t } from '../i18n'
import { useTracking } from '../tracking'
import { setWindowHandlerRouter } from '../windowHandlerRouter'
// import { ControlledBackground } from './Background'
import { Footer } from './Footer'
import { Header } from './Header'
import { HeartIcon, HomeIcon, InfoCircleIcon, UserIcon } from './Icon'
import {
  LOCALSTORAGE_TC_CONSENT_KEY,
  TermsAndConditions,
} from './TermAndConditions'

const MOBILE_BOTTOM_BAR_ID = 'mobile-bottom-bar'

const SESSION_SCROLL_PREFIX = 'scroll-offset-'

const SCROLL_BLACK_LIST = [
  /^\/evenement\/(.*?)$/,
  /^\/poi\/(.*?)$/,
  /^\/ville\/(.*?)\/evenement\/(.*?)$/,
]

export function RootLayout(props: ParentProps): JSX.Element {
  const tracker = useTracking()

  const isRouting = useIsRouting()

  useBeforeLeave((e) => {
    // if ('pluginWebUpdateNotice_' in window) {
    //   window.pluginWebUpdateNotice_.checkUpdate()
    // }
    if (!SCROLL_BLACK_LIST.some((rg) => rg.test(window.location.pathname))) {
      window.sessionStorage.setItem(
        SESSION_SCROLL_PREFIX + e.from.pathname,
        window.scrollY.toString(),
      )
    }

    tracker.capture('$pageview', {
      url: window.origin + String(e.to),
    })
  })

  let prevRouting = false
  createEffect(() => {
    if (!isRouting() && prevRouting) {
      const y = window.sessionStorage.getItem(
        SESSION_SCROLL_PREFIX + window.location.pathname,
      )
      if (
        y != null &&
        !SCROLL_BLACK_LIST.some((rg) => rg.test(window.location.pathname))
      ) {
        window.scrollTo({ top: Number(y), left: 0 })
      }
    }
    prevRouting = isRouting()
  })

  const navigator = useNavigate()

  setWindowHandlerRouter(navigator)

  const [showTC, setShowTC] = createSignal(
    localStorage.getItem(LOCALSTORAGE_TC_CONSENT_KEY) !== 'yes',
  )

  return (
    <FrontStoresProvider>
      <Show when={showTC()}>
        <TermsAndConditions
          onConfirm={() => {
            setShowTC(false)
          }}
        />
      </Show>
      <div
        class="relative"
        style={{
          'margin-top': 'env(safe-area-inset-top)',
        }}
      >
        <div class="lkl-container flex min-h-screen flex-col content-start">
          <GutterMeasure />
          <Header />
          <SuspenseList revealOrder="backwards">{props.children}</SuspenseList>
          {IS_MOBILEAPP ? <div class="h-40" /> : <Footer />}
        </div>
      </div>
      <div
        id={MOBILE_BOTTOM_BAR_ID}
        class="pointer-events-none fixed bottom-0 left-0 right-0 z-10 flex w-full flex-col justify-end"
      >
        <MobileMenu />
      </div>
      <AbortManager />
    </FrontStoresProvider>
  )
}

function MobileMenu(): JSX.Element {
  const params = useParams<{ townSlug?: string }>()
  const location = useLocation()

  return (
    <div
      class="pointer-events-auto order-last flex w-full justify-between bg-dark-blue lg:hidden"
      style={{ 'padding-bottom': 'env(safe-area-inset-bottom)' }}
    >
      <A
        href={`${params.townSlug != null ? `/ville/${params.townSlug}` : '/'}${location.search}`}
        class="flex flex-1 justify-center py-3.5"
        classList={{
          'text-apple-green': location.pathname === '/',
        }}
        aria-label={t('accessibility.home_button')}
      >
        <HomeIcon
          class="size-6"
          strokeWidth={location.pathname === '/' ? 3 : 2}
        />
      </A>
      <A
        href={`/favorites${location.search}`}
        class="flex flex-1 justify-center py-3.5"
        classList={{
          'text-apple-green': location.pathname === '/favorites',
        }}
        aria-label={t('accessibility.fav_button')}
      >
        <HeartIcon
          class="size-6"
          strokeWidth={location.pathname === '/favorites' ? 3 : 2}
        />
      </A>
      <A
        href={`/profile${location.search}`}
        class="flex flex-1 justify-center py-3.5"
        classList={{
          'text-apple-green': location.pathname === '/profile',
        }}
        aria-label={t('accessibility.profile_button')}
      >
        <UserIcon
          class="size-6"
          strokeWidth={location.pathname === '/profile' ? 3 : 2}
        />
      </A>
    </div>
  )
}

function AbortManager(): JSX.Element {
  const navigator = useNavigate()

  createEffect(() => {
    onAbort(() => {
      console.warn('onAbort redirect to singin page...')
      toast.error(t('toasts.on_abort'), {
        duration: 3000,
        icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
      })
      navigator('/auth/login')
    })
  })

  return null
}

export function MobilePortal(props: ParentProps): JSX.Element {
  return (
    <Portal mount={document.getElementById(MOBILE_BOTTOM_BAR_ID) ?? undefined}>
      {props.children}
    </Portal>
  )
}

function GutterMeasure(): JSX.Element {
  let containerRef: HTMLDivElement | undefined

  function handleWindoWResize(): void {
    if (containerRef) {
      setGutterWidth((window.innerWidth - containerRef.offsetWidth) / 2)
    }
  }
  onMount(handleWindoWResize)
  window.onresize = handleWindoWResize

  const { setGutterWidth } = useFrontStoresCtx()
  return <div ref={containerRef} />
}
