import { useNavigate } from '@solidjs/router'
import { createMutation } from '@tanstack/solid-query'
import { createMemo, type JSX, untrack } from 'solid-js'
import toast from 'solid-toast'

import { useAppStoresCtx } from '../appStores'
import { type PoiId, type ThemeName } from '../db/schema.constants'
import { useFrontStoresCtx } from '../frontStores'
import { t } from '../i18n'
import { type FavFromLocationState } from '../pages/favorites/favorites.constants'
import { InfoCircleIcon } from './Icon'

export function FavoriteButton(props: {
  poiId: PoiId
  captureFrom: FavFromLocationState
  captureThemes: ThemeName[]
  children: (
    handleClick: () => void,
    isFavorite: boolean,
    loading: boolean,
  ) => JSX.Element
}): JSX.Element {
  const navigator = useNavigate()
  const { userInfo } = useAppStoresCtx()
  const { favoritesIds, toggleFavorite } = useFrontStoresCtx()

  const togglingMutation = createMutation(() => ({
    mutationFn: async (): Promise<void> =>
      toggleFavorite(props.poiId, props.captureFrom, props.captureThemes),
  }))

  const loading = createMemo(
    () => togglingMutation.isPending || favoritesIds.isLoading,
  )

  const isFavorite = createMemo(
    () => favoritesIds.data?.includes(props.poiId) ?? false,
  )

  return (
    <>
      {props.children(
        () => {
          const user = untrack(() => userInfo.data)
          if (!user) {
            toast.error(t('toasts.on_abort'), {
              duration: 3000,
              icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
            })
            navigator('/auth/login')
            return
          }
          togglingMutation.mutate()
        },
        isFavorite(),
        loading(),
      )}
    </>
  )
}
