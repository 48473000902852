if (import.meta.env.DEV) {
  await import('solid-devtools')
  const overlay = await import('@solid-devtools/overlay')
  overlay.attachDevtoolsOverlay()
}
import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { CapacitorSwipeBackPlugin } from 'capacitor-swipe-back-plugin'
import { render } from 'solid-js/web'
import SuperTokens from 'supertokens-web-js'
import { type WindowHandlerInterface } from 'supertokens-web-js/lib/build/windowHandler/types'
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'
import EmailVerification from 'supertokens-web-js/recipe/emailverification'
import Session from 'supertokens-web-js/recipe/session'
import ThirdParty from 'supertokens-web-js/recipe/thirdparty'
import { config } from 'telefunc/client'

import App from './App'
import { IS_MOBILEAPP, MOBILE_APP_URL } from './config'
import getCookieHandler from './cookieHandler'
import { windowHandlerRouter } from './windowHandlerRouter'

if (IS_MOBILEAPP) {
  void CapacitorUpdater.addListener('appReady', (evt) => {
    console.log('Capgo updater appReady evt', evt)
    void SplashScreen.hide()
  })

  void CapacitorUpdater.addListener('downloadComplete', (evt) => {
    console.log('Capgo updater downloadComplete evt', evt)
    void SplashScreen.hide()
  })

  void CapacitorUpdater.addListener('downloadFailed', (evt) => {
    console.log('Capgo updater downloadFailed evt', evt)
    void SplashScreen.hide()
  })

  void CapacitorUpdater.notifyAppReady()
  void CapacitorSwipeBackPlugin.enable()
}

if (
  (window.location.hostname === 'pro.lokaly.fr' ||
    window.location.hostname === 'prostaging.lokaly.fr') &&
  !window.location.pathname.startsWith('/org')
) {
  window.location.href =
    '/org' + window.location.pathname + window.location.search
}

SuperTokens.init({
  appInfo: {
    apiDomain: IS_MOBILEAPP ? MOBILE_APP_URL : window.location.origin,
    apiBasePath: '/auth-api',
    appName: 'lokaly',
  },
  recipeList: [
    Session.init({
      tokenTransferMethod: IS_MOBILEAPP ? 'header' : 'cookie',
    }),
    EmailVerification.init(),
    EmailPassword.init(),
    ThirdParty.init(),
  ],
  cookieHandler: IS_MOBILEAPP ? getCookieHandler : undefined,
  windowHandler: IS_MOBILEAPP
    ? (original: WindowHandlerInterface): WindowHandlerInterface => ({
        ...original,
        location: {
          ...original.location,
          assign: (url): void => {
            if (windowHandlerRouter == null) {
              throw new Error(
                'call setWindowHandlerRouter before calling windowHandler.location.assign',
              )
            }
            windowHandlerRouter(url.toString())
          },
          setHref: (url): void => {
            if (windowHandlerRouter == null) {
              throw new Error(
                'call setWindowHandlerRouter before calling windowHandler.location.setHref',
              )
            }
            windowHandlerRouter(url.toString())
          },
        },
      })
    : undefined,
})

const sessionId =
  sessionStorage.getItem('session-id') ?? window.crypto.randomUUID()

sessionStorage.setItem('session-id', sessionId)
config.httpHeaders = {
  'x-lokaly-session-id': sessionId,
}

if (IS_MOBILEAPP) {
  config.telefuncUrl = `${MOBILE_APP_URL}/_telefunc`
}

render(() => <App />, document.getElementById('root') ?? document.body)
