import type { ThemeName } from '../../../db/schema.constants'

const FILENAMES = [
  'cinema_1',
  'cinema_2',
  'cinema_3',
  'cinema_4',
  'cinema_5',
  'cinema_6',
  'cinema_7',
  'cinema_8',
  'cinema_9',
  'cinema_10',
  'cinema_11',
  'concerts_1',
  'concerts_2',
  'concerts_3',
  'concerts_4',
  'concerts_5',
  'concerts_6',
  'concerts_7',
  'concerts_8',
  'concerts_9',
  'concerts_10',
  'concerts_11',
  'conference_1',
  'conference_2',
  'conference_3',
  'conference_4',
  'conference_5',
  'conference_6',
  'conference_7',
  'conference_8',
  'conference_9',
  'conference_10',
  'degustations_1',
  'degustations_2',
  'degustations_3',
  'degustations_4',
  'degustations_5',
  'degustations_6',
  'degustations_7',
  'degustations_8',
  'degustations_9',
  'degustations_10',
  'degustations_11',
  'degustations_12',
  'degustations_13',
  'evenements_artistiques_1',
  'evenements_artistiques_2',
  'evenements_artistiques_3',
  'expositions_1',
  'expositions_2',
  'expositions_3',
  'expositions_4',
  'expositions_5',
  'expositions_6',
  'expositions_7',
  'expositions_8',
  'expositions_9',
  'expositions_10',
  'expositions_11',
  'expositions_12',
  'expositions_13',
  'famille_1',
  'famille_2',
  'famille_3',
  'famille_4',
  'famille_5',
  'famille_6',
  'fetes_1',
  'fetes_2',
  'fetes_3',
  'fetes_4',
  'fetes_5',
  'fetes_6',
  'marches_et_brocantes_1',
  'marches_et_brocantes_2',
  'marches_et_brocantes_3',
  'marches_et_brocantes_4',
  'marches_et_brocantes_5',
  'marches_et_brocantes_6',
  'marches_et_brocantes_7',
  'marches_et_brocantes_8',
  'musees_1',
  'musees_2',
  'musees_3',
  'musees_4',
  'musees_5',
  'promenades_a_cheval_1',
  'promenades_a_cheval_2',
  'promenades_a_cheval_3',
  'promenades_a_cheval_4',
  'promenades_a_cheval_5',
  'promenades_a_cheval_6',
  'promenades_a_pied_1',
  'promenades_a_pied_2',
  'promenades_a_pied_3',
  'promenades_a_pied_4',
  'promenades_a_pied_5',
  'promenades_a_pied_6',
  'promenades_a_velo_1',
  'promenades_a_velo_2',
  'promenades_a_velo_3',
  'promenades_a_velo_4',
  'promenades_a_velo_5',
  'promenades_a_velo_6',
  'randonnees_1',
  'randonnees_2',
  'randonnees_3',
  'randonnees_4',
  'randonnees_5',
  'randonnees_6',
  'son_et_lumiere_feu_d_artifice_1',
  'son_et_lumiere_feu_d_artifice_2',
  'son_et_lumiere_feu_d_artifice_3',
  'son_et_lumiere_feu_d_artifice_4',
  'son_et_lumiere_feu_d_artifice_5',
  'son_et_lumiere_feu_d_artifice_6',
  'spectacles_1',
  'spectacles_2',
  'spectacles_3',
  'spectacles_4',
  'spectacles_5',
  'spectacles_6',
  'spectacles_7',
  'spectacles_8',
  'spectacles_9',
  'sports_1',
  'sports_2',
  'sports_3',
  'sports_4',
  'theatre_1',
  'theatre_2',
  'theatre_3',
  'theatre_4',
  'theatre_5',
  'tourisme_1',
  'tourisme_2',
  'tourisme_3',
  'tourisme_4',
] as const

export type PlaceholderFilename = (typeof FILENAMES)[number]

const PLACEHOLDERS = {
  cinema: [
    'cinema_1',
    'cinema_2',
    'cinema_3',
    'cinema_4',
    'cinema_5',
    'cinema_6',
    'cinema_7',
    'cinema_8',
    'cinema_9',
    'cinema_10',
    'cinema_11',
  ],
  concerts: [
    'concerts_1',
    'concerts_2',
    'concerts_3',
    'concerts_4',
    'concerts_5',
    'concerts_6',
    'concerts_7',
    'concerts_8',
    'concerts_9',
    'concerts_10',
    'concerts_11',
  ],
  conference: [
    'conference_1',
    'conference_2',
    'conference_3',
    'conference_4',
    'conference_5',
    'conference_6',
    'conference_7',
    'conference_8',
    'conference_9',
    'conference_10',
  ],
  degustations: [
    'degustations_1',
    'degustations_2',
    'degustations_3',
    'degustations_4',
    'degustations_5',
    'degustations_6',
    'degustations_7',
    'degustations_8',
    'degustations_9',
    'degustations_10',
    'degustations_11',
    'degustations_12',
    'degustations_13',
  ],
  'evenements-artistiques': [
    'evenements_artistiques_1',
    'evenements_artistiques_2',
    'evenements_artistiques_3',
  ],
  expositions: [
    'expositions_1',
    'expositions_2',
    'expositions_3',
    'expositions_4',
    'expositions_5',
    'expositions_6',
    'expositions_7',
    'expositions_8',
    'expositions_9',
    'expositions_10',
    'expositions_11',
    'expositions_12',
    'expositions_13',
  ],
  famille: [
    'famille_1',
    'famille_2',
    'famille_3',
    'famille_4',
    'famille_5',
    'famille_6',
  ],
  fetes: ['fetes_1', 'fetes_2', 'fetes_3', 'fetes_4', 'fetes_5', 'fetes_6'],
  'marches-et-brocantes': [
    'marches_et_brocantes_1',
    'marches_et_brocantes_2',
    'marches_et_brocantes_3',
    'marches_et_brocantes_4',
    'marches_et_brocantes_5',
    'marches_et_brocantes_6',
    'marches_et_brocantes_7',
    'marches_et_brocantes_8',
  ],
  musees: ['musees_1', 'musees_2', 'musees_3', 'musees_4', 'musees_5'],
  'promenades-a-cheval': [
    'promenades_a_cheval_1',
    'promenades_a_cheval_2',
    'promenades_a_cheval_3',
    'promenades_a_cheval_4',
    'promenades_a_cheval_5',
    'promenades_a_cheval_6',
  ],
  'promenades-a-pied': [
    'promenades_a_pied_1',
    'promenades_a_pied_2',
    'promenades_a_pied_3',
    'promenades_a_pied_4',
    'promenades_a_pied_5',
    'promenades_a_pied_6',
    'randonnees_1',
    'randonnees_2',
    'randonnees_3',
    'randonnees_4',
    'randonnees_5',
    'randonnees_6',
  ],
  'promenades-a-velo': [
    'promenades_a_velo_1',
    'promenades_a_velo_2',
    'promenades_a_velo_3',
    'promenades_a_velo_4',
    'promenades_a_velo_5',
    'promenades_a_velo_6',
  ],
  'son-et-lumiere-feu-d-artifice': [
    'son_et_lumiere_feu_d_artifice_1',
    'son_et_lumiere_feu_d_artifice_2',
    'son_et_lumiere_feu_d_artifice_3',
    'son_et_lumiere_feu_d_artifice_4',
    'son_et_lumiere_feu_d_artifice_5',
    'son_et_lumiere_feu_d_artifice_6',
  ],
  spectacles: [
    'spectacles_1',
    'spectacles_2',
    'spectacles_3',
    'spectacles_4',
    'spectacles_5',
    'spectacles_6',
    'spectacles_7',
    'spectacles_8',
    'spectacles_9',
  ],
  sports: ['sports_1', 'sports_2', 'sports_3', 'sports_4'],
  theatre: ['theatre_1', 'theatre_2', 'theatre_3', 'theatre_4', 'theatre_5'],
  tourisme: ['tourisme_1', 'tourisme_2', 'tourisme_3', 'tourisme_4'],
} as const satisfies Record<ThemeName, PlaceholderFilename[]>

export function getPlaceholderFilename(
  theme: ThemeName,
  index = Math.floor(Math.random() * 100),
): PlaceholderFilename {
  const filename = PLACEHOLDERS[theme][index % PLACEHOLDERS[theme].length]
  return filename
}

export function isPlaceholderFilename(
  arg: unknown,
): arg is PlaceholderFilename {
  return typeof arg === 'string' && FILENAMES.includes(arg)
}
