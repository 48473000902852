import { type JSX } from 'solid-js'

import { t } from '../i18n'

export function NotFound(): JSX.Element {
  return (
    <div class="h-screen">
      <h1 class="mx-auto text-center text-3xl">{t('page_not_found')}</h1>
    </div>
  )
}
