import { App as CapApp } from '@capacitor/app'
import { MetaProvider } from '@solidjs/meta'
import { Router } from '@solidjs/router'
import { QueryClient, QueryClientProvider } from '@tanstack/solid-query'
import { type JSX, lazy, onMount, Suspense } from 'solid-js'
import { Toaster } from 'solid-toast'

import { ROUTES } from '#/router'

import { AppStoresProvider } from './appStores'
import { BackgroundLayout } from './components/Background'
import { TrackingProvider } from './tracking'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const SolidQueryDevtools = import.meta.env.DEV
  ? lazy(async () => import('./TanStackQueryDevTool'))
  : (): JSX.Element => null

export default function App(): JSX.Element {
  onMount(async () => {
    await CapApp.addListener('backButton', async ({ canGoBack }) => {
      if (canGoBack) {
        history.back()
      } else {
        await CapApp.minimizeApp()
      }
    })

    await CapApp.addListener('appUrlOpen', (event) => {
      const url = new URL(event.url)
      const to =
        url.protocol === 'lokalyapp:'
          ? '/' + url.host + url.pathname + url.search
          : url.pathname + url.search
      window.location.href = to
    })
  })

  return (
    <TrackingProvider>
      <MetaProvider>
        <QueryClientProvider client={queryClient}>
          <AppStoresProvider>
            <div class="relative w-full">
              <div class="w-dvw" id="root-dvw" />
              <Router root={BackgroundLayout}>{ROUTES}</Router>
            </div>
          </AppStoresProvider>
          <Toaster position="top-center" gutter={8} />
          <Suspense>
            <SolidQueryDevtools />
          </Suspense>
        </QueryClientProvider>
      </MetaProvider>
    </TrackingProvider>
  )
}
