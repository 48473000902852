import { DatePicker as DatePickerPrimitive } from '@ark-ui/solid/date-picker'
import { CalendarDate, isWeekend, today } from '@internationalized/date'
import { For, Index, type JSX, Show } from 'solid-js'

import { type DateString } from '#db/schema.constants'

import { t } from '../i18n'
import { buttonVariants } from './Button'
import { LeftChevronIcon, RightChevronIcon } from './Icon'

const TODAY = today('Europe/Paris')
const TOMORROW = TODAY.add({ days: 1 })
const [START_NEXT_WEEKEND, END_NEXT_WEEKEND] = ((): [
  CalendarDate,
  CalendarDate,
] => {
  let _startNextWeekend = TODAY.copy()
  while (!isWeekend(_startNextWeekend, 'fr')) {
    _startNextWeekend = _startNextWeekend.add({ days: 1 })
  }
  let _endNextWeekend = _startNextWeekend.copy()
  while (isWeekend(_endNextWeekend, 'fr')) {
    _endNextWeekend = _endNextWeekend.add({ days: 1 })
  }
  _endNextWeekend = _endNextWeekend.add({ days: -1 })
  return [_startNextWeekend, _endNextWeekend]
})()

export const DATE_PRESETS = [
  {
    name: 'today',
    range: [TODAY, TODAY],
  },
  {
    name: 'tomorrow',
    range: [TOMORROW, TOMORROW],
  },
  {
    name: 'nextWeekend',
    range: [START_NEXT_WEEKEND, END_NEXT_WEEKEND],
  },
] as const satisfies Array<{
  name: DatePresetName
  range: [CalendarDate, CalendarDate]
}>

export type DatePresetName = 'today' | 'tomorrow' | 'nextWeekend'

export function DatePicker(props: {
  value?: [DateString] | [DateString, DateString] | null
  onChange?: (arg: [DateString] | [DateString, DateString] | null) => void
  hidePreset?: boolean
}): JSX.Element {
  return (
    <DatePickerPrimitive.Root
      open
      locale="fr"
      selectionMode="range"
      value={props.value?.map((d) => {
        const [year, month, day] = d.split('-')
        return new CalendarDate(Number(year), Number(month), Number(day))
      })}
      onValueChange={(e) => {
        const day1 = e.value.at(0)
        const day2 = e.value.at(1)

        props.onChange?.(
          day2 && day1
            ? [day1.toString() as DateString, day2.toString() as DateString]
            : day1
              ? [day1.toString() as DateString]
              : null,
        )
      }}
    >
      <DatePickerPrimitive.Context>
        {(context) => {
          return (
            <>
              <Show when={!props.hidePreset}>
                <div class="flex gap-2">
                  <For each={DATE_PRESETS}>
                    {(p) => (
                      <DatePickerPrimitive.PresetTrigger
                        value={p.range}
                        name={p.name}
                        class={buttonVariants({ variant: 'solid' })}
                      >
                        {t(`date_picker.${p.name}`)}
                      </DatePickerPrimitive.PresetTrigger>
                    )}
                  </For>
                </div>
              </Show>
              <DatePickerPrimitive.View
                view="day"
                class="mt-2 rounded-lg bg-grey-50 px-6 py-4"
              >
                <DatePickerPrimitive.ViewControl class="flex items-center justify-between">
                  <DatePickerPrimitive.PrevTrigger>
                    <LeftChevronIcon class="size-6" />
                  </DatePickerPrimitive.PrevTrigger>
                  <DatePickerPrimitive.RangeText />
                  <DatePickerPrimitive.NextTrigger>
                    <RightChevronIcon class="size-6" />
                  </DatePickerPrimitive.NextTrigger>
                </DatePickerPrimitive.ViewControl>

                <DatePickerPrimitive.Table class="w-full border-collapse space-y-1">
                  <DatePickerPrimitive.TableHead>
                    <DatePickerPrimitive.TableRow class="mt-2 flex w-full">
                      <Index each={context().weekDays}>
                        {(weekDay) => (
                          <DatePickerPrimitive.TableHeader class="flex-1 p-0 text-center font-semibold text-sm">
                            {weekDay().short}
                          </DatePickerPrimitive.TableHeader>
                        )}
                      </Index>
                    </DatePickerPrimitive.TableRow>
                  </DatePickerPrimitive.TableHead>

                  <DatePickerPrimitive.TableBody>
                    <Index each={context().weeks}>
                      {(week) => (
                        <DatePickerPrimitive.TableRow class="mt-2 flex w-full">
                          <Index each={week()}>
                            {(day) => (
                              <DatePickerPrimitive.TableCell
                                value={day()}
                                class="flex-1 p-0 text-center text-sm has-[[data-range-end]]:rounded-r-md has-[[data-range-start]]:rounded-l-md has-[[data-in-range]]:bg-sea-blue has-[[data-outside-range][data-in-range]]:bg-sea-blue/50 has-[[data-in-range]]:text-grey-50 has-[[data-in-range]]:first-of-type:rounded-l-md has-[[data-in-range]]:last-of-type:rounded-r-md"
                              >
                                <DatePickerPrimitive.TableCellTrigger class="inline-flex size-8 items-center justify-center rounded p-0 data-[selected]:bg-sea-blue data-[today]:bg-grey-300/50 data-[selected]:text-grey-50 data-[disabled]:opacity-30 data-[outside-range]:opacity-50 data-[selected]:opacity-100">
                                  {day().day}
                                </DatePickerPrimitive.TableCellTrigger>
                              </DatePickerPrimitive.TableCell>
                            )}
                          </Index>
                        </DatePickerPrimitive.TableRow>
                      )}
                    </Index>
                  </DatePickerPrimitive.TableBody>
                </DatePickerPrimitive.Table>
              </DatePickerPrimitive.View>
            </>
          )
        }}
      </DatePickerPrimitive.Context>
    </DatePickerPrimitive.Root>
  )
}
