import { A } from '@solidjs/router'
import { type JSX, type ParentProps } from 'solid-js'

import { ArrowLeftIcon, CheckIcon } from '#/components/Icon'
import logoUrl from '#/components/logo.webp'
import { t } from '#/i18n'

import { sample } from '../../utils'
import pic0url from './auth-0.webp'
import pic1url from './auth-1.webp'
import pic2url from './auth-2.webp'
import pic3url from './auth-3.webp'

export function AuthLayout(props: ParentProps): JSX.Element {
  const picUrl = sample([pic0url, pic1url, pic2url, pic3url])

  return (
    <div class="lkl-container flex min-h-screen flex-col content-start">
      <div class="lkl-full-section">
        <div class="grid grid-cols-1 gap-8 py-6 lg:grid-cols-2">
          <div class="gap flex flex-col gap-2 lg:gap-8">
            <div class="-mt-6 flex h-24 items-center justify-between">
              <A href="/" class="w-32">
                <img src={logoUrl} />
              </A>

              <A href="/" class="flex items-center text-xs hover:underline">
                <ArrowLeftIcon class="mr-2 inline-block size-4" />
                {t('common_back')}
              </A>
            </div>
            {props.children}
          </div>
          <div>
            <div
              class="sticky top-6 hidden h-[calc(100vh-3rem)] flex-col justify-center overflow-hidden rounded-2xl bg-cover bg-center p-8 drop-shadow-2xl lg:flex"
              style={{ 'background-image': `url(${picUrl})` }}
            >
              <div class="w-full text-balance rounded-lg bg-dark-80 p-8 px-12 py-12 !leading-snug text-grey-50 text-2xl">
                {t('my_profile.signup_banner_desc')}
                <ul>
                  <li>
                    <CheckIcon class="mr-4 mt-6 inline-block size-6 text-apple-green" />
                    {t('my_profile.signup_banner_desc_list_1_pre')}
                    <span class="font-semibold text-apple-green">
                      {t('my_profile.signup_banner_desc_list_1_accent')}
                    </span>
                    {t('my_profile.signup_banner_desc_list_1_post')}
                  </li>
                  <li>
                    <CheckIcon class="mr-4 mt-6 inline-block size-6 text-apple-green" />
                    {t('my_profile.signup_banner_desc_list_2_pre')}
                    <span class="font-semibold text-apple-green">
                      {t('my_profile.signup_banner_desc_list_2_accent')}
                    </span>
                    {t('my_profile.signup_banner_desc_list_2_post')}
                  </li>
                  <li>
                    <CheckIcon class="mr-4 mt-6 inline-block size-6 text-apple-green" />
                    {t('my_profile.signup_banner_desc_list_3_pre')}
                    <span class="font-semibold text-apple-green">
                      {t('my_profile.signup_banner_desc_list_3_accent')}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
