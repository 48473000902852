import toast from 'solid-toast'

import { addMediaFileToPoiByUrl } from './backoffice.telefunc'
import { type PoiId } from './db/schema.constants'

export async function askForImgUrl(poiId: PoiId): Promise<void> {
  const url = window.prompt("Ajouter une image à l'évènement")
  if (url == null) {
    return
  }
  const res = await addMediaFileToPoiByUrl(url, poiId)
  if (!res) {
    toast.error(`Eject de l'ajout de ${url} à ${poiId}`)
    return
  }
  toast.success(`Image ${url} ajoutée à ${poiId}`, {
    duration: 3000,
  })
}
