import { useLocation, useParams } from '@solidjs/router'
import {
  createEffect,
  createMemo,
  createSignal,
  type JSX,
  Show,
} from 'solid-js'

import { buttonVariants } from '../components/Button'
import { ArrowRightIcon } from '../components/Icon'
import { t } from '../i18n'

export function AppRedirect(): JSX.Element {
  const routeParams = useParams<{ provider: string }>()
  const location = useLocation()

  const appUrl = createMemo(
    () => `lokalyapp://auth/callback/${routeParams.provider}${location.search}`,
  )

  createEffect(() => {
    const _appUrl = appUrl()
    window.location.href = _appUrl
  })

  const [showBtn, setShowBtn] = createSignal(false)

  setTimeout(() => {
    setShowBtn(true)
  }, 2000)

  return (
    <div class="h-screen">
      <h1 class="text-3xl">{t('app-redirect.title')}</h1>
      <Show when={showBtn()}>
        <a
          class={
            buttonVariants({ variant: 'primary', size: 'lg' }) + ' mt-8 w-full'
          }
          href={appUrl()}
        >
          {t('app-redirect.btn')}
          <ArrowRightIcon class="ml-4 size-8" />
        </a>
      </Show>
    </div>
  )
}
