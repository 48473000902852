import { useCurrentMatches } from '@solidjs/router'
import {
  createMemo,
  type JSX,
  Match,
  type ParentProps,
  Show,
  Switch,
} from 'solid-js'

import blueBlobUrl from './bg-blue-blob.webp'
import bgCityMapUrl from './bg-city-map.svg'
import greenBlobUrl from './bg-green-blob.webp'
import pinkBlobUrl from './bg-pink-blob.webp'
import { FadeTransition } from './FadeTransition'

const URL_MAPPER = {
  pink: pinkBlobUrl,
  green: greenBlobUrl,
  blue: blueBlobUrl,
} as const

type BackgroundProps = {
  color: 'pink' | 'green' | 'blue'
  watermark: boolean
}

function Background(props: BackgroundProps): JSX.Element {
  return (
    <>
      <FadeTransition duration={450}>
        <Switch>
          {
            // eslint-disable-next-line solid/prefer-for
            (['pink', 'green', 'blue'] as const).map((color) => (
              <Match when={props.color === color}>
                <div
                  class="pointer-events-none fixed inset-0 bg-cover bg-center"
                  style={{ 'background-image': `url(${URL_MAPPER[color]})` }}
                />
              </Match>
            ))
          }
        </Switch>
      </FadeTransition>
      <Show when={props.watermark}>
        <div
          class="pointer-events-none fixed inset-0 bg-cover bg-left-top"
          style={{ 'background-image': `url(${bgCityMapUrl})` }}
        />
      </Show>
    </>
  )
}

export function BackgroundLayout(props: ParentProps): JSX.Element {
  const matches = useCurrentMatches()

  const bgColor = createMemo(() => {
    return (
      (matches().find((match) => match.route.info?.bgColor != null)?.route.info
        ?.bgColor as undefined | 'pink' | 'green' | 'blue') ?? 'green'
    )
  })

  const watermark = createMemo(() => {
    return (
      (matches().find((match) => match.route.info?.bgWatermark != null)?.route
        .info?.bgWatermark as undefined | boolean) ?? true
    )
  })

  return (
    <>
      <Background color={bgColor()} watermark={watermark()} />
      {props.children}
    </>
  )
}
