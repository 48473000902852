import {
  batch,
  createMemo,
  createSignal,
  type JSX,
  lazy,
  Match,
  mergeProps,
  Show,
  Suspense,
  Switch,
  untrack,
} from 'solid-js'
import { createStore } from 'solid-js/store'

import { useAppStoresCtx } from '../../appStores'
import { classMerge } from '../../components/classMerge'
import { DropDownDateFilters } from '../../components/DropDownDateFilters'
import { DropDownOptions } from '../../components/DropDownOptions'
import { FadeTransition } from '../../components/FadeTransition'
import { FeedbackFormButton } from '../../components/FeedbackFormButton'
import {
  CheckIcon,
  ChevronDownIcon,
  CrossCloseIcon,
  SlidersIcon,
} from '../../components/Icon'
import { MobilePortal } from '../../components/RootLayout'
import {
  type DateString,
  type Latitude,
  type Longitude,
} from '../../db/schema.constants'
import { useFrontStoresCtx } from '../../frontStores'
import { t } from '../../i18n'
import {
  addStyleToBodyForModal,
  deepEqual,
  removeStyleFromBodyForModal,
} from '../../utils'
import { type OptionItem } from './CitySelector'
import {
  DEFAULT_DISTANCE_KM,
  type HomeOptFilter,
  STEPS_KM,
} from './home.constants'
import { MobileSearchMenu } from './MobileSearchMenu'

const CitySelector = lazy(async () => import('./CitySelector'))

export function SearchNav(_props: {
  titlePrefix: string
  distancePrefix?: string
  class?: string
}): JSX.Element {
  const props = mergeProps(
    { distancePrefix: t('home.within_a_radius_of') },
    _props,
  )
  const [openCitySelector, setOpenCitySelector] = createSignal(false)
  const { isSmallScreen } = useAppStoresCtx()
  const { distance, position, parsedDateRange, optsArr, setFilters } =
    useFrontStoresCtx()

  const showMobileCitySelector = createMemo(() => {
    return openCitySelector() && isSmallScreen()
  })
  const showDesktopCitySelector = createMemo(() => {
    return openCitySelector() && !isSmallScreen()
  })

  const [modalStore, setModalStore] = createStore<{
    distanceSteps: number
    inputValue: OptionItem
    dateRange: [DateString, DateString] | [DateString] | null
    optFilters: HomeOptFilter[]
  }>({
    distanceSteps: 3,
    dateRange: null,
    optFilters: [],
    inputValue: {
      fullName: '',
      id: '',
      name: '',
      position: [48.85341 as Longitude, 2.3488 as Latitude],
    },
  })
  let initialStore:
    | (Omit<typeof modalStore, 'inputValue'> & { name: string })
    | null = null

  const isDirty = createMemo(() => {
    return !deepEqual(
      {
        distanceSteps: modalStore.distanceSteps,
        name: modalStore.inputValue.name,
        dateRange: modalStore.dateRange,
        optFilters: modalStore.optFilters,
      },
      initialStore,
    )
  })

  function validate(): void {
    batch(() => {
      setFilters({
        dateRange: modalStore.dateRange,
        distance:
          STEPS_KM[modalStore.distanceSteps]?.val ?? DEFAULT_DISTANCE_KM,
        position: {
          coords: modalStore.inputValue.position,
          name: modalStore.inputValue.name,
        },
        opts: modalStore.optFilters,
      })
      setOpenCitySelector(false)
    })
  }

  function openModal(): void {
    const initialStep = STEPS_KM.findIndex(
      (step) => step.val === untrack(() => distance()),
    )
    const _pos = untrack(() => position())
    const _opts = untrack(() => optsArr())
    const _date = untrack(() => parsedDateRange())
    console.log('_pos', _pos)
    batch(() => {
      const storeVal = {
        distanceSteps: initialStep > 0 ? initialStep : 3,
        inputValue: {
          fullName: _pos?.name ?? '',
          id: '',
          name: _pos?.name ?? '',
          position: _pos?.coords ?? [0 as Longitude, 47 as Latitude],
        },
        optFilters: _opts,
        dateRange: _date,
      }
      initialStore = {
        distanceSteps: storeVal.distanceSteps,
        name: storeVal.inputValue.name,
        optFilters: storeVal.optFilters,
        dateRange: storeVal.dateRange,
      }
      setModalStore(storeVal)
      setOpenCitySelector(true)
    })
  }

  let mobileButtonRef: HTMLButtonElement | undefined
  return (
    <>
      <Suspense>
        <FadeTransition
          onExit={() => {
            removeStyleFromBodyForModal()
          }}
        >
          <Show when={showDesktopCitySelector()}>
            <CitySelector
              onCancel={() => {
                setOpenCitySelector(false)
              }}
              onValidate={() => {
                validate()
              }}
              distanceSteps={modalStore.distanceSteps}
              inputValue={modalStore.inputValue}
              setInputValue={(inputValue) => {
                setModalStore('inputValue', inputValue)
              }}
              setDistanceSteps={(distanceSteps) => {
                setModalStore('distanceSteps', distanceSteps)
              }}
            />
          </Show>
        </FadeTransition>
      </Suspense>
      <nav class={classMerge('sm:pt-10', props.class)}>
        <div class="pt-4 text-6xl sm:pt-8">
          <h1
            class="inline"
            aria-label={`${props.titlePrefix} ${
              position()?.name ?? t('home.locality_name_placheholder')
            }`}
          >
            {props.titlePrefix}
          </h1>
          {/* Desktop city selector */}
          <button
            class="group ml-2 inline text-apple-green"
            aria-label={t('home.change_locality_aria_label')}
            onClick={() => {
              openModal()
            }}
          >
            {position()?.name ?? t('home.locality_name_placheholder')}
            <ChevronDownIcon class="ml-2 inline-block size-6 align-middle text-grey-100 opacity-0 transition-opacity group-hover:opacity-100" />
          </button>
        </div>
        <p class="mt-2 text-grey-300 text-base">
          {props.distancePrefix +
            t('detail.distance_km', { distance: distance() })}
        </p>

        {/* Desktop menu */}
        <div class="hidden justify-between gap-4 pt-5 sm:flex">
          <div class="flex justify-start gap-4">
            <DropDownDateFilters />
            <DropDownOptions />
          </div>
          {/* <Button variant="outline" class="px-6 py-3">
            <MapIcon class="h-6 w-6 pr-2 text-apple-green" />
            {t('home.map')}
          </Button> */}
        </div>
      </nav>

      {/* Mobile bottom nav */}
      <MobilePortal>
        <Show when={showMobileCitySelector()}>
          <MobileSearchMenu
            buttonRef={mobileButtonRef}
            dateRange={modalStore.dateRange}
            distanceSteps={modalStore.distanceSteps}
            inputValue={modalStore.inputValue}
            isDirty={isDirty()}
            optFilters={modalStore.optFilters}
            setDateRange={(val) => {
              setModalStore('dateRange', val)
            }}
            setDistanceSteps={(val) => {
              setModalStore('distanceSteps', val)
            }}
            setInputValue={(val) => {
              setModalStore('inputValue', val)
            }}
            setOptFilters={(val) => {
              setModalStore('optFilters', val)
            }}
          />
        </Show>
        <div class="pointer-events-none flex justify-between p-2.5 sm:p-6">
          {/* <button
          class="pointer-events-auto flex size-16 items-center justify-center rounded-full border-[3px] border-grey-50 bg-medium-blue"
          aria-label={t('accessibility.map_button')}
        >
          <MapIcon class="size-6" />
        </button> */}
          <FeedbackFormButton />

          <button
            ref={mobileButtonRef}
            onClick={() => {
              const wasOpen = untrack(() => openCitySelector())
              if (wasOpen) {
                validate()
                removeStyleFromBodyForModal()
              } else {
                addStyleToBodyForModal()
                openModal()
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              }
            }}
            class="pointer-events-auto flex size-16 items-center justify-center rounded-full border-[3px] border-grey-50 sm:hidden"
            classList={{
              'bg-grey-50 text-medium-blue': openCitySelector(),
              'bg-medium-blue text-grey-50': !openCitySelector(),
            }}
            aria-label={t('accessibility.filters_button')}
          >
            <Switch fallback={<SlidersIcon class="size-6" />}>
              <Match when={openCitySelector() && isDirty()}>
                <CheckIcon class="size-6" />
              </Match>
              <Match when={openCitySelector() && !isDirty()}>
                <CrossCloseIcon class="size-6" />
              </Match>
            </Switch>
          </button>
        </div>
      </MobilePortal>
    </>
  )
}
