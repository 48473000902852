import {
  type Flatten,
  flatten,
  resolveTemplate,
  translator,
} from '@solid-primitives/i18n'

import frDict from './locales/fr.json'

type RawDict = typeof frDict
export type Dictionary = Flatten<RawDict>

const flatFrDict = flatten(frDict)
export const t = translator(() => flatFrDict, resolveTemplate)
