import { useNavigate } from '@solidjs/router'
import { createEffect } from 'solid-js'
import toast from 'solid-toast'

import { useAppStoresCtx } from './appStores'
import { InfoCircleIcon } from './components/Icon'
import { t } from './i18n'
import { type UserPermission } from './pages/auth/auth.constants'

export function protectPage(
  permissions: UserPermission[],
  redirectUrl = '/auth/login',
  callbackFn = (): void => {
    toast.error(t('toasts.on_abort'), {
      duration: 3000,
      icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
    })
  },
): void {
  const { userPermissions, sessionQuery } = useAppStoresCtx()

  const navigator = useNavigate()

  createEffect(() => {
    console.log('protectPage effect running', {
      sessionQueryData: sessionQuery.data,
      userPermissionsData: userPermissions.data,
    })
    if (sessionQuery.data == null || userPermissions.data == null) {
      return
    }
    if (userPermissions.data.some((p) => permissions.includes(p))) {
      return
    }
    console.log(`redirecting to ${redirectUrl} from /events/* guard`)
    navigator(redirectUrl)
    callbackFn()
  })
}

export function protectPageNoPermissions(
  redirectUrl = '/auth/login',
  callbackFn: null | (() => void) = () => {
    toast.error(t('toasts.on_abort'), {
      duration: 3000,
      icon: <InfoCircleIcon class="size-4 text-sea-blue" />,
    })
  },
): void {
  const { sessionQuery } = useAppStoresCtx()

  const navigator = useNavigate()

  createEffect(() => {
    console.log('protectPageNoPermissions effect running', {
      sessionQueryData: sessionQuery.data,
    })
    if (sessionQuery.data == null) {
      return
    }
    if (sessionQuery.data[0]) {
      return
    }
    console.log(`redirecting to ${redirectUrl} from profile guard`)
    navigator(redirectUrl)
    callbackFn?.()
  })
}
