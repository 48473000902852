import { A, useParams } from '@solidjs/router'
import { createQuery } from '@tanstack/solid-query'
import {
  createEffect,
  createMemo,
  For,
  type JSX,
  Match,
  Show,
  Switch,
} from 'solid-js'

import { useAppStoresCtx } from '../appStores'
import { type ThemeName } from '../db/schema.constants'
import { useFrontStoresCtx } from '../frontStores'
import { t } from '../i18n'
import { DEFAULT_PAGE_SIZE } from '../pages/home/home.constants'
import {
  type GetPoisResponseItem,
  getPromotedPois,
} from '../pages/home/home.telefunc'
import { range } from '../utils'
import { EventCard, SkeletonEventCard } from './EventCard'
import { RightChevronIcon } from './Icon'
import { ScrollSection } from './ScrollSection'

export function PromotionBanner(props: {
  theme: ThemeName | null
  onLoad?: (
    event: Array<GetPoisResponseItem & { theme: ThemeName | null }>,
  ) => void
}): JSX.Element {
  const { isSmallScreen } = useAppStoresCtx()
  const { position, distance, parsedDateRange, optsArr } = useFrontStoresCtx()

  const params = useParams<{ townSlug?: string }>()

  const memoParams = createMemo(() => {
    const pos = position()?.coords
    const distanceKm = distance()
    const filterDate = parsedDateRange()
    const filtersOpts = optsArr()
    const offset = 0
    const pageSize = DEFAULT_PAGE_SIZE
    const themeName = props.theme
    if (!pos) {
      return null
    }
    return {
      pos,
      distanceKm,
      themeName,
      filtersOpts,
      filterDate,
      offset,
      pageSize,
    }
  })

  const promotionQuery = createQuery(() => {
    const params = memoParams()

    return {
      enabled: params != null,
      queryKey: ['getPromotedPois', params],
      queryFn: async (): Promise<
        Array<GetPoisResponseItem & { theme: ThemeName | null }>
      > => {
        if (!params) {
          return []
        }
        return await getPromotedPois(params)
      },
    }
  })

  createEffect(() => {
    if (!promotionQuery.data) {
      return
    }
    props.onLoad?.(promotionQuery.data)
  })

  let scrollSectionRef: HTMLDivElement | undefined
  createEffect(() => {
    void memoParams()
    scrollSectionRef?.scrollBy({
      left: -scrollSectionRef.clientWidth,
      behavior: 'smooth',
    })
  })

  return (
    <Show when={!(promotionQuery.isSuccess && promotionQuery.data.length <= 0)}>
      <div class="flex items-center justify-between pt-10">
        <h2 class="text-balance text-grey-50 text-4xl">
          <Show when={isSmallScreen()} fallback={t('home.promotion_title')}>
            <A
              href={`${
                params.townSlug != null ? `/ville/${params.townSlug}` : ''
              }/promotions?${location.search}`}
              class="hover:underline"
            >
              {t('home.promotion_title')}
            </A>
          </Show>
        </h2>

        <A
          href={`${
            params.townSlug != null ? `/ville/${params.townSlug}` : ''
          }/promotions?${location.search}`}
          class="group flex items-center gap-2"
        >
          <p class="hidden font-semibold group-hover:underline sm:inline-block">
            {t('home.see_all')}
          </p>
          <RightChevronIcon class="inline-block size-6 rounded-full transition-opacity duration-150 group-hover:opacity-100 sm:opacity-0" />
        </A>
      </div>
      <p class="text-balance pb-8 text-grey-50 text-sm">
        {t('home.promotion_subtitle')}
      </p>
      <ScrollSection
        ref={(el) => {
          scrollSectionRef = el
        }}
      >
        <Switch
          fallback={
            <For each={range(0, DEFAULT_PAGE_SIZE - 1)}>
              {() => <SkeletonEventCard size="lg" />}
            </For>
          }
        >
          <Match when={promotionQuery.isError}>
            <p class="text-coral">
              {promotionQuery.error?.message ?? 'unknown-error'}
            </p>
          </Match>
          <Match when={promotionQuery.isSuccess}>
            <For each={promotionQuery.data}>
              {(item, index) => (
                <EventCard
                  themeName={props.theme ?? item.theme ?? 'concerts'}
                  slugFr={item.slugFr}
                  poiId={item.id}
                  title={item.label}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  poiTime={item.poiTime}
                  distanceKm={item.distance / 1000}
                  locality={item.locality}
                  mediaFileId={item.mainMediaFileId}
                  mainMediaFileCredits={item.mainMediaFileCredits}
                  mainMediaFileLicense={item.mainMediaFileLicense}
                  size="lg"
                  randomIdx={index()}
                  from="homeSponsored"
                />
              )}
            </For>
          </Match>
        </Switch>
      </ScrollSection>
    </Show>
  )
}
