import type { JSX } from 'solid-js'
import { Show } from 'solid-js'

import { useAppStoresCtx } from '../appStores'
import { t } from '../i18n'
import bgCityMapUrl from './bg-city-map.svg'
import greenBlobUrl from './bg-green-blob.webp'
import { Button } from './Button'
import { ArrowRightIcon } from './Icon'
import pictoUrl from './picto.webp'

export const LOCALSTORAGE_TC_CONSENT_KEY = 'LOCALSTORAGE_TC_CONSENT_KEY'

export function TermsAndConditions(props: {
  onConfirm: () => void
}): JSX.Element {
  const { isDesktopScreen } = useAppStoresCtx()

  function confirm(): void {
    props.onConfirm()
    localStorage.setItem(LOCALSTORAGE_TC_CONSENT_KEY, 'yes')
  }
  return (
    <Show
      when={!isDesktopScreen()}
      fallback={
        <div class="fixed inset-0 z-[60] bg-dark-80">
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
            class="fixed left-1/2 top-[min(211px,calc(100vh-350px))] z-50 max-w-[589px] -translate-x-1/2 rounded bg-grey-50 p-6 text-medium-blue drop-shadow-2xl"
          >
            <h4 class="font-semibold text-xl">{t('terms.title')}</h4>
            <p>
              {t('terms.body_pre')}
              <a
                class="font-semibold hover:underline"
                href="https://lokaly.fr/mentions-legales"
                target="_blank"
              >
                {t('terms.link1')}
              </a>
              {t('terms.body_inner')}
              <a
                class="font-semibold hover:underline"
                href="https://lokaly.fr/politique-de-confidentialite"
                target="_blank"
              >
                {t('terms.link2')}
              </a>
              {t('terms.body_post')}
            </p>
            <div class="flex w-full justify-end gap-4 pt-6">
              <Button
                variant="solidInvert"
                onClick={() => {
                  confirm()
                }}
              >
                {t('terms.ok_btn')}
              </Button>
            </div>
          </div>
        </div>
      }
    >
      <div class="fixed inset-0 z-[60] bg-dark-blue">
        <div
          class="fixed inset-0 bg-cover bg-center"
          style={{ 'background-image': `url(${greenBlobUrl})` }}
        />
        <div
          class="fixed inset-0 bg-cover bg-left-top"
          style={{ 'background-image': `url(${bgCityMapUrl})` }}
        />
        <div class="fixed inset-0 z-[60] flex flex-col items-center justify-between p-8 py-16">
          <img class="w-36" src={pictoUrl} alt="" />
          <div class="text-center">
            <h3 class="font-semibold text-7xl">{t('terms.mobile_title')}</h3>
            <p class="text-lg">{t('terms.mobile_subtitle')}</p>
          </div>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              confirm()
            }}
          >
            {t('terms.mobile_ok_btn')}
            <ArrowRightIcon class="ml-2 size-6" />
          </Button>

          <p class="text-balance text-center">
            {t('terms.mobile_body_pre')}
            <a
              class="hover:unuderline font-semibold"
              href="https://lokaly.fr/mentions-legales"
              target="_blank"
            >
              {t('terms.link1')}
            </a>
            {t('terms.body_inner')}
            <a
              class="font-semibold hover:underline"
              href="https://lokaly.fr/politique-de-confidentialite"
              target="_blank"
            >
              {t('terms.link2')}.
            </a>
          </p>
        </div>
      </div>
    </Show>
  )
}
