import { type ThemeName } from '../../../db/schema.constants'
import cinema_1 from './cinema_1.webp'
import cinema_2 from './cinema_2.webp'
import cinema_3 from './cinema_3.webp'
import cinema_4 from './cinema_4.webp'
import cinema_5 from './cinema_5.webp'
import cinema_6 from './cinema_6.webp'
import cinema_7 from './cinema_7.webp'
import cinema_8 from './cinema_8.webp'
import cinema_9 from './cinema_9.webp'
import cinema_10 from './cinema_10.webp'
import cinema_11 from './cinema_11.webp'
import concerts_1 from './concerts_1.webp'
import concerts_2 from './concerts_2.webp'
import concerts_3 from './concerts_3.webp'
import concerts_4 from './concerts_4.webp'
import concerts_5 from './concerts_5.webp'
import concerts_6 from './concerts_6.webp'
import concerts_7 from './concerts_7.webp'
import concerts_8 from './concerts_8.webp'
import concerts_9 from './concerts_9.webp'
import concerts_10 from './concerts_10.webp'
import concerts_11 from './concerts_11.webp'
import conference_1 from './conference_1.webp'
import conference_2 from './conference_2.webp'
import conference_3 from './conference_3.webp'
import conference_4 from './conference_4.webp'
import conference_5 from './conference_5.webp'
import conference_6 from './conference_6.webp'
import conference_7 from './conference_7.webp'
import conference_8 from './conference_8.webp'
import conference_9 from './conference_9.webp'
import conference_10 from './conference_10.webp'
import degustations_1 from './degustations_1.webp'
import degustations_2 from './degustations_2.webp'
import degustations_3 from './degustations_3.webp'
import degustations_4 from './degustations_4.webp'
import degustations_5 from './degustations_5.webp'
import degustations_6 from './degustations_6.webp'
import degustations_7 from './degustations_7.webp'
import degustations_8 from './degustations_8.webp'
import degustations_9 from './degustations_9.webp'
import degustations_10 from './degustations_10.webp'
import degustations_11 from './degustations_11.webp'
import degustations_12 from './degustations_12.webp'
import degustations_13 from './degustations_13.webp'
import evenements_artistiques_1 from './evenements-artistiques_1.webp'
import evenements_artistiques_2 from './evenements-artistiques_2.webp'
import evenements_artistiques_3 from './evenements-artistiques_3.webp'
import expositions_1 from './expositions_1.webp'
import expositions_2 from './expositions_2.webp'
import expositions_3 from './expositions_3.webp'
import expositions_4 from './expositions_4.webp'
import expositions_5 from './expositions_5.webp'
import expositions_6 from './expositions_6.webp'
import expositions_7 from './expositions_7.webp'
import expositions_8 from './expositions_8.webp'
import expositions_9 from './expositions_9.webp'
import expositions_10 from './expositions_10.webp'
import expositions_11 from './expositions_11.webp'
import expositions_12 from './expositions_12.webp'
import expositions_13 from './expositions_13.webp'
import famille_1 from './famille_1.webp'
import famille_2 from './famille_2.webp'
import famille_3 from './famille_3.webp'
import famille_4 from './famille_4.webp'
import famille_5 from './famille_5.webp'
import famille_6 from './famille_6.webp'
import fetes_1 from './fetes_1.webp'
import fetes_2 from './fetes_2.webp'
import fetes_3 from './fetes_3.webp'
import fetes_4 from './fetes_4.webp'
import fetes_5 from './fetes_5.webp'
import fetes_6 from './fetes_6.webp'
import { getPlaceholderFilename, type PlaceholderFilename } from './filename'
import marches_et_brocantes_1 from './marches-et-brocantes_1.webp'
import marches_et_brocantes_2 from './marches-et-brocantes_2.webp'
import marches_et_brocantes_3 from './marches-et-brocantes_3.webp'
import marches_et_brocantes_4 from './marches-et-brocantes_4.webp'
import marches_et_brocantes_5 from './marches-et-brocantes_5.webp'
import marches_et_brocantes_6 from './marches-et-brocantes_6.webp'
import marches_et_brocantes_7 from './marches-et-brocantes_7.webp'
import marches_et_brocantes_8 from './marches-et-brocantes_8.webp'
import musees_1 from './musees_1.webp'
import musees_2 from './musees_2.webp'
import musees_3 from './musees_3.webp'
import musees_4 from './musees_4.webp'
import musees_5 from './musees_5.webp'
import promenades_a_cheval_1 from './promenades-a-cheval_1.webp'
import promenades_a_cheval_2 from './promenades-a-cheval_2.webp'
import promenades_a_cheval_3 from './promenades-a-cheval_3.webp'
import promenades_a_cheval_4 from './promenades-a-cheval_4.webp'
import promenades_a_cheval_5 from './promenades-a-cheval_5.webp'
import promenades_a_cheval_6 from './promenades-a-cheval_6.webp'
import promenades_a_pied_1 from './promenades-a-pied_1.webp'
import promenades_a_pied_2 from './promenades-a-pied_2.webp'
import promenades_a_pied_3 from './promenades-a-pied_3.webp'
import promenades_a_pied_4 from './promenades-a-pied_4.webp'
import promenades_a_pied_5 from './promenades-a-pied_5.webp'
import promenades_a_pied_6 from './promenades-a-pied_6.webp'
import promenades_a_velo_1 from './promenades-a-velo_1.webp'
import promenades_a_velo_2 from './promenades-a-velo_2.webp'
import promenades_a_velo_3 from './promenades-a-velo_3.webp'
import promenades_a_velo_4 from './promenades-a-velo_4.webp'
import promenades_a_velo_5 from './promenades-a-velo_5.webp'
import promenades_a_velo_6 from './promenades-a-velo_6.webp'
import randonnees_1 from './randonnees_1.webp'
import randonnees_2 from './randonnees_2.webp'
import randonnees_3 from './randonnees_3.webp'
import randonnees_4 from './randonnees_4.webp'
import randonnees_5 from './randonnees_5.webp'
import randonnees_6 from './randonnees_6.webp'
import son_et_lumiere_feu_d_artifice_1 from './son-et-lumiere-feu-d-artifice_1.webp'
import son_et_lumiere_feu_d_artifice_2 from './son-et-lumiere-feu-d-artifice_2.webp'
import son_et_lumiere_feu_d_artifice_3 from './son-et-lumiere-feu-d-artifice_3.webp'
import son_et_lumiere_feu_d_artifice_4 from './son-et-lumiere-feu-d-artifice_4.webp'
import son_et_lumiere_feu_d_artifice_5 from './son-et-lumiere-feu-d-artifice_5.webp'
import son_et_lumiere_feu_d_artifice_6 from './son-et-lumiere-feu-d-artifice_6.webp'
import spectacles_1 from './spectacles_1.webp'
import spectacles_2 from './spectacles_2.webp'
import spectacles_3 from './spectacles_3.webp'
import spectacles_4 from './spectacles_4.webp'
import spectacles_5 from './spectacles_5.webp'
import spectacles_6 from './spectacles_6.webp'
import spectacles_7 from './spectacles_7.webp'
import spectacles_8 from './spectacles_8.webp'
import spectacles_9 from './spectacles_9.webp'
import sports_1 from './sports_1.webp'
import sports_2 from './sports_2.webp'
import sports_3 from './sports_3.webp'
import sports_4 from './sports_4.webp'
import theatre_1 from './theatre_1.webp'
import theatre_2 from './theatre_2.webp'
import theatre_3 from './theatre_3.webp'
import theatre_4 from './theatre_4.webp'
import theatre_5 from './theatre_5.webp'
import tourisme_1 from './tourisme_1.webp'
import tourisme_2 from './tourisme_2.webp'
import tourisme_3 from './tourisme_3.webp'
import tourisme_4 from './tourisme_4.webp'

const FILENAME_URL = {
  cinema_1,
  cinema_2,
  cinema_3,
  cinema_4,
  cinema_5,
  cinema_6,
  cinema_7,
  cinema_8,
  cinema_9,
  cinema_10,
  cinema_11,
  concerts_1,
  concerts_2,
  concerts_3,
  concerts_4,
  concerts_5,
  concerts_6,
  concerts_7,
  concerts_8,
  concerts_9,
  concerts_10,
  concerts_11,
  conference_1,
  conference_2,
  conference_3,
  conference_4,
  conference_5,
  conference_6,
  conference_7,
  conference_8,
  conference_9,
  conference_10,
  degustations_1,
  degustations_2,
  degustations_3,
  degustations_4,
  degustations_5,
  degustations_6,
  degustations_7,
  degustations_8,
  degustations_9,
  degustations_10,
  degustations_11,
  degustations_12,
  degustations_13,
  evenements_artistiques_1,
  evenements_artistiques_2,
  evenements_artistiques_3,
  expositions_1,
  expositions_2,
  expositions_3,
  expositions_4,
  expositions_5,
  expositions_6,
  expositions_7,
  expositions_8,
  expositions_9,
  expositions_10,
  expositions_11,
  expositions_12,
  expositions_13,
  famille_1,
  famille_2,
  famille_3,
  famille_4,
  famille_5,
  famille_6,
  fetes_1,
  fetes_2,
  fetes_3,
  fetes_4,
  fetes_5,
  fetes_6,
  marches_et_brocantes_1,
  marches_et_brocantes_2,
  marches_et_brocantes_3,
  marches_et_brocantes_4,
  marches_et_brocantes_5,
  marches_et_brocantes_6,
  marches_et_brocantes_7,
  marches_et_brocantes_8,
  musees_1,
  musees_2,
  musees_3,
  musees_4,
  musees_5,
  promenades_a_cheval_1,
  promenades_a_cheval_2,
  promenades_a_cheval_3,
  promenades_a_cheval_4,
  promenades_a_cheval_5,
  promenades_a_cheval_6,
  promenades_a_pied_1,
  promenades_a_pied_2,
  promenades_a_pied_3,
  promenades_a_pied_4,
  promenades_a_pied_5,
  promenades_a_pied_6,
  promenades_a_velo_1,
  promenades_a_velo_2,
  promenades_a_velo_3,
  promenades_a_velo_4,
  promenades_a_velo_5,
  promenades_a_velo_6,
  randonnees_1,
  randonnees_2,
  randonnees_3,
  randonnees_4,
  randonnees_5,
  randonnees_6,
  son_et_lumiere_feu_d_artifice_1,
  son_et_lumiere_feu_d_artifice_2,
  son_et_lumiere_feu_d_artifice_3,
  son_et_lumiere_feu_d_artifice_4,
  son_et_lumiere_feu_d_artifice_5,
  son_et_lumiere_feu_d_artifice_6,
  spectacles_1,
  spectacles_2,
  spectacles_3,
  spectacles_4,
  spectacles_5,
  spectacles_6,
  spectacles_7,
  spectacles_8,
  spectacles_9,
  sports_1,
  sports_2,
  sports_3,
  sports_4,
  theatre_1,
  theatre_2,
  theatre_3,
  theatre_4,
  theatre_5,
  tourisme_1,
  tourisme_2,
  tourisme_3,
  tourisme_4,
} as const satisfies Record<PlaceholderFilename, string>

export function getPlaceholderUrl(
  theme: ThemeName,
  index = Math.floor(Math.random() * 100),
): string {
  const filename = getPlaceholderFilename(theme, index)
  return FILENAME_URL[filename]
}
