import { createSignal, type JSX, Show } from 'solid-js'

import { useFrontStoresCtx } from '../frontStores'
import { t } from '../i18n'
import { DatePicker } from './DatePicker'
import {
  DropdownButtonTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from './DropdownMenu'
import { CalendarIcon } from './Icon'

export function DropDownDateFilters(): JSX.Element {
  const [openedDate, setOpenedDate] = createSignal(false)
  const { parsedDateRange, selectedDatePresets, setFilters } =
    useFrontStoresCtx()
  return (
    <DropdownMenu open={openedDate()} onOpenChange={setOpenedDate}>
      <DropdownMenuTrigger>
        <DropdownButtonTrigger
          class="!leading-none"
          active={Boolean(parsedDateRange() != null)}
          open={openedDate()}
          onClickDelete={() => {
            setFilters({ dateRange: null })
          }}
        >
          <CalendarIcon class="h-6 w-6" />
          <div>
            <Show when={selectedDatePresets()} fallback={t('home.date_label')}>
              {(preset) => t(`date_picker.${preset()}`)}
            </Show>
          </div>
        </DropdownButtonTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        class="border-none bg-transparent p-0"
        style={{
          'transform-origin': 'var(--kb-menu-content-transform-origin)',
        }}
      >
        <DatePicker
          value={parsedDateRange()}
          onChange={(val) => {
            setFilters({ dateRange: val })
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
