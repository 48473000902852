export const MIN_PASSWORD_LENGTH = 10

// https://emailregex.com/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const EMAIL_VALIDATION_ERROR = 'email-validation-error'
export const PASSWORD_STRENGTH_ERROR = 'password-strength-error'
export const PASSWORD_PWNED_ERROR = 'password-pwned-error'
export const REQUIRED_ERROR = 'required-error'
export const CONFIRM_PASSWORD_MATCH_ERROR = 'confirm-password-match-error'
export const INVALID_SIREN_ERROR = 'invalid-siren-error'

export const AUTH_SOURCES = ['email', 'google', 'facebook', 'apple'] as const
export type AuthSource = (typeof AUTH_SOURCES)[number]
export const isAuthSource = (source: string): source is AuthSource =>
  AUTH_SOURCES.includes(source)

export const USER_PERMISSIONS = [
  'image_mod', // can edit images on any pois through dashboard
  'poi_mod', // can edit basic info on any pois through dashboard
  'theme_mod', // can edit themes info on any pois through dashboard
  'batch_import', // can use the csv batch importer
  'org_mod', // can approve orgs, and promote user to pro_user

  'poi_editor', // can edit pois linked to them through orgs
] as const
export type UserPermission = (typeof USER_PERMISSIONS)[number]
export const isUserPermission = (
  permission: string,
): permission is UserPermission => USER_PERMISSIONS.includes(permission)
