import { A, useMatch, useNavigate } from '@solidjs/router'
import { createMutation } from '@tanstack/solid-query'
import { For, type JSX, onMount, type ParentProps, Show } from 'solid-js'
import { signOut } from 'supertokens-web-js/recipe/session'

import { useAppStoresCtx } from '../../appStores'
import { buttonVariants } from '../../components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/DropdownMenu'
import {
  ChevronDownIcon,
  HomeIcon,
  LogOutIcon,
  UserIcon,
  UsersIcon,
} from '../../components/Icon'
import { type Dictionary, t } from '../../i18n'
import { type UserPermission } from '../auth/auth.constants'
import logoUrl from './LokalyPro.webp'

type Section = {
  link: string
  pathmatch: string
  label: keyof Dictionary
  icon: JSX.Element
  visibleTo: UserPermission | null
}

const PROFILE_SECTION = {
  link: '/org/profile',
  pathmatch: '/org/profile/*',
  label: 'org.profile.title',
  icon: <UsersIcon class="size-6" />,
  visibleTo: null,
} as const satisfies Section

const EVENTS_SECTION = {
  link: '/org/events',
  pathmatch: '/org/events/*',
  label: 'org.eventslist.title',
  icon: <HomeIcon class="size-6" />,
  visibleTo: 'poi_editor',
} as const satisfies Section

const SECTIONS = [EVENTS_SECTION, PROFILE_SECTION] as const satisfies Section[]

export default function OrgLayout(props: ParentProps): JSX.Element {
  const { userInfo, sessionQuery, userPermissions } = useAppStoresCtx()

  onMount(() => {
    if (window.location.hostname === 'lokaly.fr') {
      window.location.href =
        'https://pro.lokaly.fr' +
        window.location.pathname +
        window.location.search
    }
    if (window.location.hostname === 'staging.lokaly.fr') {
      window.location.href =
        'https://prostaging.lokaly.fr' +
        window.location.pathname +
        window.location.search
    }
  })

  const navigator = useNavigate()

  const logOutMutation = createMutation(() => {
    return {
      mutationFn: async (): Promise<void> => {
        await signOut()
        void sessionQuery.refetch()
        navigator('/org/auth/login')
      },
    }
  })

  return (
    <>
      <header class="z-10 flex w-full items-center justify-between gap-6 p-6 pb-0">
        <A href="/org/" class="w-48 min-w-36">
          <img src={logoUrl} alt="Logo" />
        </A>
        <Show
          when={userInfo.data}
          fallback={
            <A
              class={buttonVariants({ variant: 'outline', size: 'lg' })}
              href="/org/auth/login"
            >
              {t('header.sign_in')}
            </A>
          }
        >
          {(info) => (
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div class={buttonVariants({ variant: 'solid' })}>
                    <UserIcon class="mr-2 inline-block size-6" />
                    {info().firstName}
                    <ChevronDownIcon class="ml-2 inline-block size-4" />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent class="flex-col gap-2">
                  <div class="mx-2 border-b border-grey-300 px-4 pb-2 text-center text-dark-65 text-xs">
                    {info().email}
                  </div>
                  <button
                    disabled={logOutMutation.isPending}
                    onClick={() => {
                      logOutMutation.mutate()
                    }}
                    class="flex items-center justify-start gap-2 rounded-sm py-1.5 pl-2 pr-12 hover:bg-grey-100"
                  >
                    <LogOutIcon class="size-4" />
                    {t('my_profile.signout_button')}
                  </button>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </Show>
      </header>
      <div class="flex gap-12 p-6">
        <Show when={userInfo.data}>
          <div>
            <div class="sticky top-10 block">
              <div class="flex flex-col items-start justify-start rounded-lg bg-dark-30 p-6 text-xs">
                <For each={SECTIONS}>
                  {(section) => {
                    const selected = useMatch(() => section.pathmatch)
                    return (
                      <Show
                        when={
                          section.visibleTo == null ||
                          userPermissions.data?.includes(section.visibleTo)
                        }
                      >
                        <A
                          class="flex w-full gap-2 rounded-lg p-4 uppercase outline-light-20 transition-colors hover:underline"
                          classList={{
                            outline: selected() != null,
                          }}
                          href={section.link}
                        >
                          {section.icon}
                          <span>{t(section.label)}</span>
                        </A>
                      </Show>
                    )
                  }}
                </For>
              </div>
            </div>
          </div>
        </Show>
        <div class="z-10 flex-1">{props.children}</div>
      </div>
    </>
  )
}
