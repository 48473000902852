import { createMemo, type JSX } from 'solid-js'

import abstractURL from '../pages/auth/abstract-hero.svg'
import { exhaustiveCheck } from '../utils'
import emptyURL from './empty-hero.svg'
import defaultURL from './hero.svg'
import welcomeURL from './welcome-hero.svg'

export function EmptyState(props: {
  title: JSX.Element
  description: JSX.Element
  hero: 'empty' | 'default' | 'abstract' | 'welcome'
  children?: JSX.Element
}): JSX.Element {
  const heroUrl = createMemo(() => {
    switch (props.hero) {
      case 'empty':
        return emptyURL
      case 'default':
        return defaultURL
      case 'welcome':
        return welcomeURL
      case 'abstract':
        return abstractURL
      default:
        exhaustiveCheck(props.hero)
        return defaultURL
    }
  })
  return (
    <div class="mt-10 flex flex-col gap-6 rounded-md bg-dark-20 p-6 md:flex-row md:gap-12 md:p-12">
      <img class="mx-auto w-36 md:mx-0" src={heroUrl()} alt="" />
      <div class="flex flex-col items-center gap-6 md:items-start">
        <h3 class="whitespace-pre-wrap text-center text-3xl md:text-justify">
          {props.title}
        </h3>
        <p class="max-w-[640px]">{props.description}</p>
        <div class="flex flex-wrap justify-center gap-7 md:justify-start">
          {props.children}
        </div>
      </div>
    </div>
  )
}
