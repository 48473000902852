import { Meta, Title } from '@solidjs/meta'
import { A } from '@solidjs/router'
import { createMemo, type JSX } from 'solid-js'

import { useAppStoresCtx } from '../../appStores'
import { buttonVariants } from '../../components/Button'
import { t } from '../../i18n'
import coneUrl from './cones.webp'
import heroUrl from './hero-org.webp'
import phoneUrl from './mobile-menphis.webp'

export default function OrgLandingPage(): JSX.Element {
  const { sessionQuery } = useAppStoresCtx()

  const ctaUrl = createMemo(() => {
    return sessionQuery.data ? '/org/auth/welcome' : '/org/auth/signup'
  })
  return (
    <>
      <Title>{t('org.landingpage.head_title')}</Title>
      <Meta
        name="description"
        content={t('org.landingpage.meta_description')}
      />
      <div class="mx-auto flex max-w-[1200px] flex-col gap-24 text-pretty py-12">
        <div class="grid grid-cols-1 gap-6 md:grid-cols-[4fr_3fr]">
          <div class="flex flex-col gap-6">
            <h1 class="text-3xl">{t('org.landingpage.title')}</h1>
            <p class="text-xl">{t('org.landingpage.subtitle')}</p>
            <p>{t('org.landingpage.desc')}</p>
            <A
              class={buttonVariants({ size: 'lg', variant: 'primary' })}
              href={ctaUrl()}
            >
              {t('org.landingpage.cta_btn')}
            </A>
          </div>
          <img class="mx-auto max-h-96" src={heroUrl} />
        </div>
        <div class="grid grid-cols-1 gap-6 md:grid-cols-[3fr_4fr]">
          <img class="order-2 mx-auto max-h-80 md:order-1" src={phoneUrl} />
          <div class="order-1 flex flex-col gap-6 md:order-2">
            <h2 class="text-3xl">{t('org.landingpage.title2')}</h2>
            <p>{t('org.landingpage.desc2')}</p>
            <ul class="list-disc pl-8 marker:text-apple-green">
              <li>{t('org.landingpage.item21')}</li>
              <li>{t('org.landingpage.item22')}</li>
              <li>{t('org.landingpage.item23')}</li>
              <li>{t('org.landingpage.item24')}</li>
            </ul>
          </div>
        </div>
        <div class="flex flex-col gap-6">
          <h2 class="mx-auto text-2xl">{t('org.landingpage.title3')}</h2>
          <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <h3 class="text-lg">{t('org.landingpage.itemTitle31')}</h3>
              <p>{t('org.landingpage.item31')}</p>
            </div>
            <div>
              <h3 class="text-lg">{t('org.landingpage.itemTitle32')}</h3>
              <p>{t('org.landingpage.item32')}</p>
            </div>
            <div>
              <h3 class="text-lg">{t('org.landingpage.itemTitle33')}</h3>
              <p>{t('org.landingpage.item33')}</p>
            </div>
            <div>
              <h3 class="text-lg">{t('org.landingpage.itemTitle34')}</h3>
              <p>{t('org.landingpage.item34')}</p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-[1fr_3fr]">
          <div>
            <h2 class="pb-6 text-2xl">{t('org.landingpage.faq_title')}</h2>
            <img class="mx-auto hidden max-h-80 sm:block" src={coneUrl} />
          </div>
          <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-4">
              <h3 class="text-lg">{t('org.landingpage.faqItemTitle1')}</h3>
              <p>{t('org.landingpage.faqItemDesc1')}</p>
            </div>
            <div class="flex flex-col gap-4">
              <h3 class="text-lg">{t('org.landingpage.faqItemTitle2')}</h3>
              <p>{t('org.landingpage.faqItemDesc2')}</p>
            </div>
            <div class="flex flex-col gap-4">
              <h3 class="text-lg">{t('org.landingpage.faqItemTitle3')}</h3>
              <p>{t('org.landingpage.faqItemDesc3')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
